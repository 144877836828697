@use "../mixins" as *;
@use "../variables" as *;

.contents--main--privacy {
    padding: 7rem 0 13rem 0;
    background-color: $bg-primary-lighter;
}
.privacy-sec {
    &__inner {
        @include lay-content-inner;
        @include zero-auto();
        @include bp-down(md) {
        }
    }
    &__main-catch {
    }
    &__ttl {
        font-size: 2.5rem;
        margin-top: 6rem;
    }
    &__txt {
        margin-top: 2rem;
    }
    .lay-list-desc-set-green {
        margin-top: 2rem;
        .lay-list-desc-set-green__item {
            & + li {
                margin-top: .5rem;
            }
        }
    }
}
.transaction-sec {
    @include lay-content-inner;
    @include zero-auto(4rem,0);
    &__ttl {
        font-size: 2rem;
        &--sub{
            margin-top: 2rem;
            font-size: 1.8rem;
        }
    }
    .lay-list-desc-set-green {
        margin-top: 2rem;
        .lay-list-desc-set-green__item {
            margin-bottom: .5rem;
        }
    }
    >p {
        margin-top: 3rem;
    }
}
