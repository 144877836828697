@use "../mixins" as *;
@use "../variables" as *;

.mainvisual {
    &--voice {
        background-image: url(/assets/image/voice/img_mv_voice.png);
    }
}
/* ---------------------------------------------
*   voice
--------------------------------------------- */
.voice {
    $host: &;

    &__container {
        max-width: 1000px;
        margin: 0 auto;
        padding: 90px 0 60px;
        box-sizing: border-box;

        @include bp-down(md) {
            padding: pxtovw(50, "sp") pxtovw(15, "sp");
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
		justify-content: space-between;
    }

    &__item {
        width: 24%;
		margin-bottom: 2rem;
		background-color: #ccc;
        @include bp-down(md) {
            width: calc(50% - 5px);
            margin-top: pxtovw(30, "sp");
            margin-bottom: 1rem;
            margin-left: 0;
        }
    }

    &__link {
        cursor: pointer;
        outline: none;
        padding: .5rem;
        appearance: none;
        display: block;
        width: 100%;
		position: relative;
		&:before {
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			background-color: rgba(131, 162, 7, .4);;
			top: 0;
			left: 0;
			opacity: 0;
			transition: .3s;
		}
		&:after {
			content: "";
			position: absolute;
			background-image: url(../images/common/icon-zoom.svg);
			width: 50px;
			height: 50px;
			left: 0;
			right: 0;
			margin: auto;
			top: 0;
			bottom: 0;
			opacity: 0;
			transition: .3s;
		}
		&:hover {
			&:before {
				opacity: 1;
			}
			&:after {
				opacity: 1;
			}
		}
		img {
			width: 100%;
		}
    }

    &__name {
        margin-top: 8px;
        // font-size: pxtorem(20, 'pc');
        font-weight: bold;

        @include bp-down(md) {
            margin-top: pxtovw(10, "sp");
            font-size: pxtorem(20, "sp");
        }
    }

    &__place {
        // font-size: pxtorem(14, 'pc');
        font-weight: bold;
        color: $txt-primary;

        @include bp-down(md) {
            font-size: pxtorem(14, "sp");
        }
    }
}

/*  voice-modal
--------------------------------------------- */
.voice-modal {
    $host: &;
    width: 600px;

    @include bp-down(md) {
        width: 100%;
    }

    &__detail {
        padding: 25px 40px 30px;
        background-color: $bg-primary-lighter;

        @include bp-down(md) {
            padding: pxtovw(10, "sp") pxtovw(15, "sp") pxtovw(15, "sp");
        }
    }

    &__name {
        // font-size: pxtorem(20, 'pc');
        font-weight: bold;

        @include bp-down(md) {
            font-size: pxtorem(20, "sp");
        }
    }

    &__place {
        font-size: pxtorem(14, "pc");
        font-weight: bold;
        color: $txt-primary;

        @include bp-down(md) {
            font-size: pxtorem(14, "sp");
        }
    }

    &__contents {
        margin-top: 28px;
        padding: 15px 18px;
        background: url(/assets/image/common/bg_message.png) 0 0 no-repeat;
        background-size: 100% 100%;
        text-align: left;

        @include bp-down(md) {
            margin-top: pxtovw(15, "sp");
            padding: pxtovw(15, "sp");
        }
    }

    &__contents-txt {
        padding: 2px 0;
        background: linear-gradient(transparent 92%, $bg-muted 0%);
        background-size: 100% 2.1em;
        font-size: pxtorem(14, "pc");
        line-height: 2.1;

        @include bp-down(md) {
            padding: pxtovw(2, "sp") 0;
            font-size: pxtorem(14, "sp");
        }
    }

    &__message {
        margin-top: 30px;
        text-align: left;
        position: relative;

        @include bp-down(md) {
            margin-top: pxtovw(20, "sp");
        }
    }

    &__message-head {
        position: absolute;
        display: inline-block;
        margin-left: 14px;
        padding: 3px 25px;
        // font-size: pxtorem(16, 'pc');
        font-weight: bold;
        color: $txt-light;
        background-color: $bg-primary;
        border-radius: 15px;
        top: -15px;

        @include bp-down(md) {
            margin-left: pxtovw(14, "sp");
            padding: pxtovw(2, "sp") pxtovw(20, "sp");
            font-size: pxtorem(12, "sp");
            border-radius: pxtovw(15, "sp");
        }
    }

    &__message-img {
        margin-top: -13px;

        @include bp-down(md) {
            margin-top: pxtovw(-12, "sp");
        }
    }
    &__message-txt {
        background-color: #e7e7e7;
        padding: 30px 15px 15px;
        margin-top: 40px;

        @include bp-down(md) {
            margin-top: pxtovw(-12, "sp");
        }
    }
}
