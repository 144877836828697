@use "mediaquery" as *;

@mixin zero-auto($mt-def: 0, $mb-def: 0) {
    margin: $mt-def auto $mb-def auto;
}
@mixin lay-margin-def($margin-pc-def: 5rem, $margin-sp-def: $margin-pc-def / 2) {
    margin: $margin-pc-def auto;
    @include bp-down(md) {
        margin: $margin-sp-def auto;
    }
}
@mixin lay-margin-top($margin-pc-def: 4rem, $margin-sp-def: calc($margin-pc-def / 2)) {
    margin: $margin-pc-def auto;
    @include bp-down(md) {
        margin: $margin-sp-def auto;
    }
}
