@use "../mixins" as *;
@use "../variables" as *;

.shop-main {
    background-color: $bg-primary-lighter;
    padding: 7rem 0 0 0;
    @include bp-down(md) {
        padding: 3rem 0 4rem 0;
    }
    &__ttl {
        text-align: center;
        font-size: 3rem;
        @include bp-down(md) {
            font-size: 2.3rem;
        }
    }
    &__ttl-under {
        text-align: center;
        font-weight: bold;
        margin-bottom: 2rem;
        font-size: 3rem;
    }
}
.shop-list {
    @include lay-content-inner;
    @include zero-auto(3.5rem, 0);
    @include bp-down(md) {
        margin-top: 2rem;
    }
    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &:after {
            content: "";
            visibility: hidden;
            width: 31%;
            @include bp-down(md) {
                width: 48%;
            }
        }

        // &--shop-top {
        //     &:after {
        //         content: none;
        //     }
        // }
        &__item {
            width: 31%;
            margin-bottom: 3rem;
            position: relative;
            // &:nth-child(2) {
            //     margin: 0 2%;
            //     @include bp-down(md) {
            //         margin: 0;
            //     }
            // }
            // &:last-child {
            //     margin-left: 2%;
            //     @include bp-down(md) {
            //         margin: 0;
            //     }
            // }
            @include bp-down(md) {
                width: 48%;
                margin-bottom: 2rem;
            }
            &--niigata {
                &:before {
                    content: "新潟";
                    color: #fff;
                    background-color: #226bae;
                    text-align: center;
                    width: 75px;
                    height: 25px;
                    position: absolute;
                    font-size: 1.3rem;
                    padding-top: 0.3rem;
                    z-index: 2;
                    @include bp-down(md) {
                        font-size: 1.1rem;
                        width: 50px;
                        height: 22px;
                    }
                }
            }
            &--gunma {
                &:before {
                    content: "群馬";
                    color: #fff;
                    background-color: #381e7f;
                    text-align: center;
                    width: 75px;
                    height: 25px;
                    position: absolute;
                    font-size: 1.3rem;
                    padding-top: 0.3rem;
                    z-index: 2;
                    @include bp-down(md) {
                        font-size: 1.1rem;
                        width: 50px;
                        height: 22px;
                    }
                }
            }
            &__name {
                font-weight: bold;
                text-align: center;
                font-size: 1.7rem;
                margin-top: 2rem;
                @include bp-down(md) {
                    font-size: 1.5rem;
                    margin-top: 1rem;
                }
            }
            &__notice{
                color:$red;
            }
            a {
                display: block;
                &:hover {
                    img {
                        opacity: 0.8;
                    }
                    // opacity: 0.8;
                }
            }
        }
    }
}
.shop-thumbs-wrap {
    max-width: 1000px;
    padding: 0 20px;
    margin: 0 auto;
    @include bp-down(md) {
        width: 90%;
    }
}
.shop-child-inner-link {
    width: 100%;
    padding: 0 15px;
    @include bp-up(md) {
        padding: 0 20px;
        width: 1000px;
    }
    @include zero-auto();
    &__list {
        display: flex;
        justify-content: center;
        &__item {
            width: 50%;
            text-align: center;
            @include bp-up(md) {
                width: 25%;
            }
            &__link {
                text-align: center;
                display: block;
                background-color: $bg-primary;
                color: #fff;
                padding: 1.5rem 0;
                position: relative;
                font-weight: bold;
                @include ie {
                    padding: 1.6rem 0 1rem 0;
                }
                &:hover {
                    opacity: 0.8;
                    color: #fff;
                }
                &:before {
                    display: inline-block;
                    margin-right: 1rem;
                    content: "";
                    background-image: url(../images/common/arrow-wh-under.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 12px;
                    height: 8px;
                    z-index: 2;
                }
            }
            & + li {
                border-left: 2px solid #fff;
            }
        }
    }
}
.shop-thumbs {
    // @include lay-content-inner;
    @include zero-auto(5rem, 0);
    display: flex;
    justify-content: space-between;
    @include bp-down(md) {
        width: 100%;
    }
    &__list {
        @include bp-down(md) {
            img {
                width: 100%;
                max-width: 100%;
            }
        }
    }
    &--row {
        max-width: 1000px;
        padding: 0 20px;
        @include zero-auto(5rem, 0);
    }
    &--row__notice{
        line-height: 1.8;
        max-width: 700px;
        margin:0 auto 30px;
        .name{
            text-align: end;
        }
        a{
            text-decoration: underline;
        }
    }
}
.shop-info-table-wrap {
    position: relative;
    &__inner {
        max-width: 1000px;
        padding: 0 20px;
        margin: 0 auto;
    }
    &:after {
        content: "";
        position: absolute;
        background-color: #f0f2d5;
        width: 100%;
        height: 10.7rem;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
    table {
        width: 100%;
        tr {
            td {
                a {
                    font-weight: bold;
                }
            }
        }
    }
}
.lay-table {
    &--shop {
        @include lay-content-inner;
        @include zero-auto(5rem, 0);
        border: 1px solid #ccc;
        position: relative;
        z-index: 2;
        tr {
            border-bottom: 1px solid #ccc;
        }
        th {
            background-color: #f6f6f6;
            padding: 1.5rem 1.5rem 1.5rem 3rem;
            @include bp-down(md) {
                display: block;
                width: 100%;
                padding: 1.5rem;
            }
        }
        td {
            background-color: #fff;
            padding: 1.5rem 1.5rem 1.5rem 3rem;
            border-left: 1px solid #ccc;
            @include bp-down(md) {
                display: block;
                width: 100%;
                border-left: none;
                border-top: 1px solid #ccc;
                padding: 1.5rem;
            }
        }
    }
}
.shop-map {
    background-color: #f0f2d5;
    padding: 2rem 2rem 8rem 2rem;
    @include bp-down(md) {
        padding-bottom: 4rem;
    }
    &__inner {
        max-width: 960px;
        @include zero-auto();
        border: 1px solid #ccc;
        background-color: #fff;
        padding: 0.5rem;
        iframe {
            width: 100%;
            height: 350px;
            border: 1px solid #ccc;
            vertical-align: bottom;
        }
    }
}
.map-blank {
    padding: 1.5rem 0;
    display: block;
    line-height: 1;
    text-align: center;
    width: 250px;
    @include zero-auto(2rem, 0);
    font-weight: bold;
    @include ie {
        padding: 1.6rem 0 1rem 0;
        line-height: 2;
    }
    img {
        margin-left: 1rem;
        @include ie {
            margin-bottom: 1rem;
        }
    }
}
.staff-set {
    background-color: #fff;
    text-align: center;
    padding: 8rem 0;
    @include bp-down(md) {
        padding: 4rem 0;
    }
    &__sub {
        font-weight: bold;
        color: $txt-primary-dark;
        font-size: 1.7rem;
    }
    &__ttl {
        font-size: 3rem;
    }
}
.staff-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    @include lay-content-inner;
    @include zero-auto(4rem, 0);
    &:before {
        content: "";
        visibility: hidden;
        width: 23.5%;
        order: 1;
        @include bp-down(md) {
            width: 49%;
        }
    }
    &:after {
        content: "";
        visibility: hidden;
        width: 23.5%;
        // order: 0;
        @include bp-down(md) {
            width: 49%;
        }
    }
    &__item {
        width: 23.5%;
        position: relative;
        margin-bottom: 4rem;
        @include bp-down(md) {
            width: 49%;
            margin-bottom: 2rem;
        }
        &__thumb {
            border: 1px solid #ccc;
            padding: 0.5rem;
            @include bp-down(md) {
                padding: 0.3rem;
            }
        }
        &__quali {
            font-size: 1.1rem;
            margin-top: 1rem;
        }
        &__name {
            position: relative;
            z-index: 1;
            background-color: $bg-primary-dark;
            color: #fff;
            text-align: center;
            font-size: 1.7rem;
            padding: 0.5rem 0;
            width: 170px;
            margin: -17px auto 0;
            font-weight: bold;
            @include ie {
                line-height: 0;
                padding: 2rem 0 1.4rem 0;
            }
            @include bp-down(md) {
                font-size: 1.2rem;
                width: 80%;
            }
        }
    }
}
.shop-back-link {
    font-weight: bold;
    font-size: 1.7rem;
    text-align: center;
    width: 250px;
    @include zero-auto(5rem, 0);
    display: block;
    padding: 1.5rem;
    @include ie {
        padding: 1.6rem 0 1rem 0;
    }
    @include bp-down(md) {
        font-size: 1.5rem;
    }
}
.inner-link {
    background-color: $bg-primary-lighter;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 5rem;
    @include bp-down {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    &__inner {
        width: 100rem;
        margin-left: auto;
        margin-right: auto;
        @include bp-down {
            width: 100%;
        }
    }
    &__set {
        display: flex;
        justify-content: center;
        >li {
            width: 24%;
            @include bp-down {
                width: 50%;
            }
            & + li {
                margin-left: .2rem;
            }
            a {
                text-align: center;
                display: block;
                border: .2rem solid $bg-primary;
                background-color: #fff;
                color: $bg-primary;
                padding: 1.5rem 0 2rem 0;
                position: relative;
                font-weight: bold;
                &::before {
                    position: absolute;
                    margin-right: 1rem;
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-left: .2rem solid $bg-primary;
                    border-bottom: .2rem solid $bg-primary;
                    transform: rotate(-45deg) translateY(-50%);
                    bottom: .6rem;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }
    }
}
.shop-back-link-wrapper {
    background-color: $bg-primary-lighter;
    padding-bottom: 5rem;
}
