@use "../mixins" as *;
@use "../variables" as *;

/* ---------------------------------------------
*   pankuzu
--------------------------------------------- */
.pankuzu-box {
    @include bp-down(md) {
        overflow-y: scroll;
        height: 40px;
        position: relative;
    }
}

body.single {
    .pankuzu {
        // width: 800px;
        @include bp-down(md) {
            // width: 1000px;
        }
    }
}
//ブログページ用カスタム
body.blog {
    .pankuzu {
        &__list {
            &__item {
                a {
                    font-size: 1.1rem;
                    span {
                        font-size: 1.1rem;
                    }
                }
                > span {
                    font-size: 1.1rem;
                    font-weight: 500;
                    @include hover-opacity();
                }
            }
        }
    }
}
.pankuzu {
    // position: absolute;
    left: 0;
    right: 0;
    // width: 1000px;
    margin: 0 auto;
    padding: 12px 0 12px 30px;
    box-sizing: border-box;
    z-index: 3;
    background-color: $bg-primary-dark;

    @include bp-down(md) {
        padding: .4rem 1rem;
        overflow-y: scroll;
        width: 100%;
    }

    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include bp-down(md) {
            width: 1000px;
        }
        &__item {
            position: relative;
            padding-left: 35px;
            > a {
                > span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                    display: block;
                    overflow: hidden;
                    font-size: 1.2rem;
                }
            }
            @include bp-down(md) {
                padding-left: pxtovw(35, "sp");
            }

            &:before {
                position: absolute;
                top: -1px;
                bottom: 0;
                left: 12px;
                margin: auto;
                content: "";
                vertical-align: middle;
                width: 6px;
                height: 6px;
                border-top: 2px solid #fff;
                border-right: 2px solid #fff;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);

                @include bp-down(md) {
                    left: pxtovw(15, "sp");
                    border-width: pxtovw(4, "sp") 0 pxtovw(4, "sp")
                        pxtovw(6, "sp");
                }
            }

            &:first-of-type {
                padding-left: 0;

                &:before {
                    content: none;
                }
            }
        }
    }

    &__link {
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: #fff;
        @include hover-opacity();

        @include bp-down(md) {
            font-size: 1rem;
        }
    }

    &__txt {
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: #fff;

        @include bp-down(md) {
            font-size: 1rem;
        }
    }
}
