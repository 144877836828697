@use "../variables" as *;
//(初期値はmd(min-width768px))
@mixin bp-up($breakpoint: md) {
    @media #{map-get($breakpoint-up, $breakpoint)} {
        @content;
    }
}

//(初期値はmd((max-width: 767.98px))
@mixin bp-down($breakpoint: md) {
    @media #{map-get($breakpoint-down, $breakpoint)} {
        @content;
    }
}

@mixin ie {
    @media all and (-ms-high-contrast: none) {
      @content;
    }
  }
