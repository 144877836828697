@use "../variables" as *;
@use "mediaquery" as *;

@mixin lay-padding-sm($padding-pc-sm: 3rem, $padding-sp-sm: calc($padding-pc-sm /2)) {
    padding: $padding-pc-sm 0;
    @include bp-down(md) {
        padding: $padding-sp-sm 0;
    }
}
@mixin lay-padding-mid($padding-pc-mid: 4rem, $padding-sp-mid: calc($padding-pc-mid /2)) {
    padding: $padding-pc-mid 0;
    @include bp-down(md) {
        padding: $padding-sp-mid 0;
    }
}
@mixin lay-padding-def($padding-pc-def: 5rem, $padding-sp-def: calc($padding-pc-def /2)) {
    padding: $padding-pc-def 0;
    @include bp-down(md) {
        padding: $padding-sp-def 0;
    }
}
@mixin lay-padding-lg($padding-pc-lg: 8rem, $padding-sp-def: $padding-pc-lg /2) {
    padding: $padding-pc-lg 0;
    @include bp-down(md) {
        padding: $padding-sp-lg 0;
    }
}
