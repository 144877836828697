@use "../mixins" as *;
@use "../variables" as *;

.contents--main--fiduciaryduty {
    padding: 7rem 0 6rem 0;
    background-color: $bg-primary-lighter;
    @include bp-down(md) {
        padding: 3rem 0 4rem 0;
    }
}
.fiduciaryduty-ttl {
    font-size: 2.5rem;
    text-align: center;
    @include bp-down(md) {
        font-size: 2rem;
    }
}
.fiduciaryduty-catch {
    text-align: center;
    margin-top: 2rem;
    @include bp-down(md) {
        width: 90%;
        margin:  1rem auto 0 auto;
        font-size: 1.3rem;
        text-align: left;
    }
}
.fd-policy {
    margin-bottom: 3rem;
    @include bp-up(md) {
        margin-bottom: 7rem;
    }
}
.fd-lay-set {
    @include lay-content-inner;
    @include zero-auto(3.5rem, 0);
    background-color: #fff;
    padding: 5rem;
    border: 2px solid #ccc;
    @include bp-down(md) {
        margin-top: 2rem;
        padding: 3rem;
    }
    &__list {
        &__item {
            &__ttl {
                font-size: 2rem;
                @include bp-down(md) {
                    font-size: 1.7rem;
                }
            }
            &__catch {
                margin-top: 2.5rem;
                @include bp-down(md) {
                    margin-top: 1.5rem;
                }
            }
            &:first-child {
                padding-bottom: 4rem;
                @include bp-down(md) {
                    padding-bottom: 2rem;
                }
            }
            &:nth-child(2) {
                padding: 4rem 0;
                border-top: 1px dashed #ccc;
                border-bottom: 1px dashed #ccc;
                @include bp-down(md) {
                    padding: 2rem 0;
                }
            }
            &:last-child {
                padding-top: 4rem;
                @include bp-down(md) {
                    padding-top: 2rem;
                }
            }
        }
    }
    .lay-list-desc-set-green{
        @include bp-up(md) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &__item{
            margin-right: 2rem;
        }
    }
    }
}
.effort {
    background-color: #fff;
    padding: 7rem 0;
    @include bp-down(md) {
        padding: 3rem 0;
    }
    .fd-lay-set {
        border: 2px solid $border-primary;
        background-color: $bg-primary-lighter;
    }
}
.effort02 {
    padding: 7rem 0;
    @include bp-down(md) {
        padding: 3rem 0;
    }
}
