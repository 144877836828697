@use "../mixins" as *;
@use "../variables" as *;

.procedure-page-head-sub {
    font-size: 1.5rem;
    text-align: center;
    @include bp-down(md) {
        text-align: left;
        width: 90%;
        margin: 0 auto;
    }
}
.contents--main--procedure {
    background-color: $bg-primary-lighter;
    padding: 7rem 0 13rem 0;
    @include bp-down(md) {
        padding: 3rem 0 4rem 0;
    }
}
.ins-content-wrap {
    @include lay-content-inner;
    @include zero-auto(2rem,0);
    @include bp-down(md) {
        overflow-x: scroll;
    }
}
.table-lay-col3 {
    width: 100%;
    @include ie {
        width: 960px;
        table-layout: fixed;
    }
    @include bp-down(md) {
        width: 960px;
    }
    th {
        background-color: #E9E9E9;
        padding: 2.5rem 3rem;
        border: 1px solid #ccc;
        vertical-align: top;
        font-size: 1.7rem;
        width: 20%;
        @include ie {
            width: 120px;
        }
        @include bp-down(md) {
            width: 100px;
            padding: 1.6rem 1rem;
            font-size: 1.4rem;
        }
    }
    td {
        border: 1px solid #ccc;
        padding: 2.5rem 3rem;
        vertical-align: top;
        background-color: #fff;
        font-weight: bold;
        @include ie {
            width: 480px;
        }
        @include bp-down(md) {
            padding: 1.6rem 1rem;
            font-size: 1.4rem;
        }
        &.bg-gray {
            background-color: #F6F6F6;
            width: 31%;
            @include ie {
                width: 290px;
            }
            @include bp-down {
                width: 140px;
            }
        }
        .rec-cap {
            color: #E53263;
            margin-top: 1rem;
        }
    }
}
.my-note-bnr {
    border: 2px solid $bg-muted-light;
    width: 370px;
    margin-top: 1rem;
    a {
        &:hover {
            opacity: .8;
        }
    }
}
