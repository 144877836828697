@use "../mixins" as *;
@use "../variables" as *;

.txt--justify {
    @include text-align-justify();
}

.txt--bold {
    font-weight: bold;
}

.txt--primary {
    color: $txt-primary;
}
.link-border{
    text-decoration: underline !important;
}
// Note
ul.note {
    margin-left: 0;
    list-style: none;
}

p.note,
dd.note,
ul.note li {
    margin-top: .5em;
    margin-left: 1.4em;
    font-size: 1.4rem;
    line-height: 1.6;
    text-align: left;
    text-indent: -1.4em;
    color: $gray-darker;
}

li p.note {
    margin-top: 0 !important;
}

.tr{
    text-align: right;
}
