@use "../mixins" as *;
@use "../variables" as *;

.ins-table-ttl {
    text-align: center;
    font-size: 3rem;
    margin-top: 7rem;
    @include bp-down(md) {
        font-size: 2rem;
    }
}
.cycle-ins__ttl {
    margin-top: 0;
}
.insurance-direct-main {
    padding-top: 7rem;
    padding-bottom: 13rem;
    background-color: $bg-primary-lighter;
    @include bp-down(md) {
        padding-top: 3rem;
        padding-bottom: 4rem;
    }
}
.ins-sec {
    @include lay-content-inner();
    @include zero-auto();
}
.ins-table {
    width: 100%;
    border-collapse: collapse;
    border: solid 1px #CCCCCC;
    margin-top: 3.5rem;
    &.medical {
        tr {
            th {
                a {
                    width: 225px;
                    @include bp-down {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    tr {
        border-bottom: 1px solid #ccc;
        background-color: #F6F6F6;
    }
    &__head {
        padding: 3rem 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: #F6F6F6;
        width: 300px;
        border-collapse: collapse;
        @include bp-down(md) {
            padding: 1rem 0;
            width: 100%;
        }
        a {
            &.no-click {
                pointer-events: none;
            }
            @include bp-down(md) {
                display: block;
                text-align: center;
            }
            &:hover {
                img {
                    opacity: .7;
                }
            }
        }
        @include bp-down(md) {
            flex-direction: column;
            display: block;
            align-items: center;
            >img {
                display: block;
                margin: 0 auto;
            }
        }
        .txt-link {
            font-size: 1.3rem;
            margin-top: 1rem;
            color: $red;
        }
    }
    &__data {
        background-color: #fff;
        padding: 3rem;
        vertical-align: top;
        border-left: 1px solid #ccc;
        box-sizing: border-box;
        border-collapse: collapse;
        width: 660px;
        @include bp-down(md) {
            display: block;
            width: 100%;
            padding: 2rem;
            border-left: none;
            border-top: 1px solid #ccc;
        }
        &__ttl {
            color: $txt-primary-dark;
            span {
                display: block;
                color: #000;
                font-size: 1.3rem;
            }
        }
        &__txt {
            font-size: 1.3rem;
            margin-top: 1.5rem;
            &--cap {
                font-size: 1rem;
                a{
                    text-decoration:underline;
                }
            }
        }
    }
}
.direct-number{
    text-align: right;
    margin-top: 10px;
    font-size: 80%;
}
