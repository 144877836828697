@use "../mixins" as *;
@use "../variables" as *;

/* ---------------------------------------------
*   html, body
--------------------------------------------- */
html {
    font-size: 62.5%;
}
html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}
body {
    @include goth;
    line-height: $line-height-pc;
    min-width: $minwidth-pc * 1px;
    font-size: $font-size-default;
    color: #000;

    @include bp-down(md) {
        line-height: $line-height-sp;
        min-width: $minwidth-sp * 1px;
        font-size: $font-size-default-sp;
    }

    &.js-sp-menu-opened {
        overflow: hidden;
    }
}
.inline-bold {
    font-weight: bold;
}
/* ---------------------------------------------
*   <a> tag
--------------------------------------------- */
a {
    color: #000;
    text-decoration: none;
    &:hover {
        color: #666;
        text-decoration: none;
    }
}

/* ---------------------------------------------
*   <img> tag
--------------------------------------------- */
img {
    max-width: 100%;
    width: auto;
    height: auto;
    vertical-align: bottom;
}

/* ---------------------------------------------
*   <sup> tag
--------------------------------------------- */
sup {
    vertical-align: 30%;
    font-size: 40%;
}
.numb-list {
    counter-reset: item;
    list-style-type: none;
    padding-left: 0;
    list-style-position: inside;
    &__item {
        padding-left: 1.6rem;
        position: relative;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            counter-increment: item;
            content: counter(item) ".";
        }
    }
}
.dots-list {
    &__item {
        position: relative;
        padding-left: 1.6rem;
        &:before {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 3px;
            position: absolute;
            top: 10px;
            left: 3px;
            background-color: $bg-muted;
        }
    }
}
// p {
//     line-height: 2;
// }
.cap-list {
    &__item {
        position: relative;
        padding-left: 1.6rem;
        font-size: 80%;
        &:before {
            content: "※";
            font-size: .6rem;
            position: absolute;
            top: 2px;
            left: 0;
        }
    }
}

/* Noto
---------------------------------------- */
@font-face {
    font-family: "NotoSansCJKjp";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.eot");
    src: local("Noto Sans CJK JP"),
        url("/assets/fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.woff")
            format("woff"),
        url("/assets/fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.otf")
            format("opentype");
}
/* medium */
@font-face {
    font-family: "NotoSansCJKjp";
    font-style: normal;
    font-weight: 500;
    src: url("/assets/fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.eot");
    src: local("Noto Sans CJK JP Medium"),
        url("/assets/fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.woff")
            format("woff"),
        url("/assets/fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.otf")
            format("opentype");
}
/* bold */
@font-face {
    font-family: "NotoSansCJKjp";
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.eot");
    src: local("Noto Sans CJK JP Bold"),
        url("/assets/fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.woff")
            format("woff"),
        url("/assets/fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.otf")
            format("opentype");
}
