@use "../mixins" as *;
@use "../variables" as *;

// Common
// -----------------------------------------------------------------------------
@for $i from 0 through 20 {
    @if $i == 0 {
        .pt--0 {
            padding-top: 0 !important;
        }
        .pr--0 {
            padding-right: 0 !important;
        }
        .pb--0 {
            padding-bottom: 0 !important;
        }
        .pl--0 {
            padding-left: 0 !important;
        }
    }
    @else {
        .pt--#{$i * 5} {
            padding-top:#{$i * 5}px !important;
        }
        .pr--#{$i * 5} {
            padding-right:#{$i * 5}px !important;
        }
        .pb--#{$i * 5} {
            padding-bottom: 0 !important;
        }
        .pl--#{$i * 5} {
            padding-left:#{$i * 5}px !important;
        }
    }
}

// Middle Range
// -----------------------------------------------------------------------------
@include bp-up(md) {
    @for $i from 0 through 20 {
        @if $i == 0 {
            .pt-pc--0 {
                padding-top: 0 !important;
            }
            .pr-pc--0 {
                padding-right: 0 !important;
            }
            .pb-pc--0 {
                padding-bottom: 0 !important;
            }
            .pl-pc--0 {
                padding-left: 0 !important;
            }
        }
        @else {
            .pt-pc--#{$i * 5} {
                padding-top:#{$i * 5}px !important;
            }
            .pr-pc--#{$i * 5} {
                padding-right:#{$i * 5}px !important;
            }
            .pb-pc--#{$i * 5} {
                padding-bottom: 0 !important;
            }
            .pl-pc--#{$i * 5} {
                padding-left:#{$i * 5}px !important;
            }
        }
    }
}

// Small Range
// -----------------------------------------------------------------------------
@include bp-down(md) {
    @for $i from 0 through 20 {
        @if $i == 0 {
            .pt-sp--0 {
                padding-top: 0 !important;
            }
            .pr-sp--0 {
                padding-right: 0 !important;
            }
            .pb-sp--0 {
                padding-bottom: 0 !important;
            }
            .pl-sp--0 {
                padding-left: 0 !important;
            }
        }
        @else {
            .pt-sp--#{$i * 5} {
                padding-top:#{$i * 5}px !important;
            }
            .pr-sp--#{$i * 5} {
                padding-right:#{$i * 5}px !important;
            }
            .pb-sp--#{$i * 5} {
                padding-bottom:#{$i * 5}px !important;
            }
            .pl-sp--#{$i * 5} {
                padding-left:#{$i * 5}px !important;
            }
        }
    }
}
