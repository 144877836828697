@use "../mixins" as *;
@use "../variables" as *;


.mainvisual {

	&--service {
        background-image: url(/assets/image/service/img_mv_service.png);
    }
}

/* ---------------------------------------------
*   service
--------------------------------------------- */
.service {
	$host: &;

	&__container {
		max-width: 1000px;
		margin: 0 auto;
		padding: 100px 0 60px;
		box-sizing: border-box;

		@include bp-down(md) {
			padding: pxtovw(50, 'sp') pxtovw(15, 'sp');
		}
	}

	&__contents {
		margin-top: 80px;

		@include bp-down(md) {
			margin-top: pxtovw(60, 'sp');
		}
	}

	&__lead {
		// font-size: pxtorem(16, 'pc');
		font-weight: bold;

		@include bp-down(md) {
			font-size: pxtorem(16, 'sp');
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;

		@include bp-down(md) {
			display: block;
		}
	}

	&__item {
		width: 484px;
		margin-top: 38px;
		margin-left: 32px;

		@include bp-down(md) {
			width: 100%;
			margin-top: pxtovw(30, 'sp');
			margin-left: 0;
		}

		&:nth-of-type(2n+1) {
			margin-left: 0;
		}
	}

	&__head {
		margin-top: 20px;
		// font-size: pxtorem(20, 'pc');
		font-weight: bold;
		color: $txt-primary;

		@include bp-down(md) {
			margin-top: pxtovw(10, 'sp');
			font-size: pxtorem(20, 'sp');
		}
	}

	&__txt {
		margin-top: 15px;
		// font-size: pxtorem(14, 'pc');
		font-weight: 500;

		@include bp-down(md) {
			margin-top: pxtovw(5, 'sp');
			font-size: pxtorem(14, 'sp');
		}
	}
}

/*  service-flow
--------------------------------------------- */
.service-flow {
	$host: &;
	background: url(/assets/image/common/bg_dots.png) 0 0 repeat;
	background-size: contain;

	&__container {
		max-width: 1000px;
		margin: 0 auto;
		padding: 60px 0 70px;
		box-sizing: border-box;

		@include bp-down(md) {
			padding: pxtovw(50, 'sp') pxtovw(15, 'sp');
		}
	}

	&__item {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-top: 70px;

		@include bp-down(md) {
			display: block;
			margin-top: pxtovw(60, 'sp');
		}

		&:before {
			content: "";
			position: absolute;
			top: -45px;
			left: 142px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 20px 14px 0 14px;
			border-color: $border-primary transparent transparent transparent;

			@include bp-down(md) {
				top: pxtovw(-40, 'sp');
				left: 50%;
				transform: translateX(-50%);
				border-width: pxtovw(20, 'sp') pxtovw(14, 'sp') 0 pxtovw(14, 'sp');
			}
		}

		&:first-of-type {
			margin-top: 0;

			&:before {
				content: none;
			}
		}
	}

	&__img {
		width: 312px;

		@include bp-down(md) {
			width: 100%;
		}

		img {
			width: 100%;
		}
	}

	&__detail {
		width: 655px;

		@include bp-down(md) {
			margin-top: pxtovw(10, 'sp');
			width: 100%;
		}
	}

	&__step {
		display: inline-block;
		padding: 1px 18px;
		// font-size: pxtorem(16, 'pc');
		font-weight: bold;
		color: $txt-light;
		background-color: $bg-primary;
		border-radius: 13px;

		@include bp-down(md) {
			padding: pxtovw(1, 'sp') pxtovw(18, 'sp');
			font-size: pxtorem(16, 'sp');
			border-radius: pxtovw(15, 'sp');
		}
	}

	&__head {
		margin-top: 20px;
		// font-size: pxtorem(20, 'pc');
		font-weight: bold;
		color: $txt-primary;

		@include bp-down(md) {
			margin-top: pxtovw(10, 'sp');
			font-size: pxtorem(20, 'sp');
		}
	}

	&__txt {
		margin-top: 15px;
		// font-size: pxtorem(14, 'pc');
		font-weight: 500;
		line-height: 1.75;
		letter-spacing: -0.03em;

		@include bp-down(md) {
			margin-top: pxtovw(5, 'sp');
			font-size: pxtorem(14, 'sp');
		}
	}
}
