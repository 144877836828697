@use "../mixins" as *;
@use "../variables" as *;

.smile-logo {
    // position: absolute;
    position: relative;
    z-index: 2;
    // margin: auto;
    // width: 350px;
    // max-width: 960px;
    // height: 250px;
    img {
        width: 259px;
        height: 250px;
        position: absolute;
        top: -20rem;
        left: 0;
        right: 0;
        margin: auto;
    }
    @include bp-down(md) {
        img {
            width: 140px;
            height: auto;
            top: -10rem;
        }
    }
}
.housingloan--main {
    padding: 10rem 0 13rem 0;
    position: relative;
    background-color: $bg-primary-lighter;
    @include bp-down(md) {
        padding: 3rem 0 4rem 0;
    }
    .page-head {
        padding-top: 0;
        background-color: $bg-primary-lighter;
        @include bp-down(md) {
            padding-bottom: 6rem;
        }
    }
}
.ttl-ribon {
    position: absolute;
    top: -2.6rem;
    left: 0;
    right: 0;
    margin: auto;
    color: #fff;
    background-image: url(../images/insurance/ttl-ribon-bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    width: 380px;
    font-size: 2rem;
    padding: 1.2rem 0;
    box-sizing: border-box;
    @include bp-down(md) {
        width: 90%;
        font-size: 1.6rem;
        top: -2.5rem;
    }
}
.housingloan-consult {
    background-color: #f0f2d5;
    padding: 7rem 0;
    position: relative;
    &__set {
        max-width: 800px;
        @include zero-auto();
        @include bp-down(md) {
            width: 90%;
        }
        &__list {
            &__item {
                padding: 2rem 2rem 2rem 7.5rem;
                font-weight: bold;
                position: relative;
                @include bp-down(md) {
                    padding-left: 4.5rem;
                }
                &:before {
                    position: absolute;
                    content: "";
                    width: 20px;
                    height: 16px;
                    background-image: url(../images/common/icon-check.svg);
                    z-index: 2;
                    background-repeat: no-repeat;
                    background-size: cover;
                    top: 2rem;
                    left: 2rem;
                    @include bp-down(md) {
                        left: 1.5rem;
                    }
                }
                &:nth-child(odd) {
                    background-color: #fff;
                }
                &:nth-child(even) {
                    background-color: #f6f6f6;
                }
            }
        }
    }
}
.thumb01 {
    img {
        width: 100%;
    }
}
.housing-howto {
    background-color: #fff;
    @include lay-content-inner;
    @include zero-auto(-30rem, 0);
    border-top: 2px solid $border-primary;
    padding: 7rem 0;
    position: relative;
    @include bp-down(md) {
        margin-top: -6rem;
        padding-top: 3rem;
    }
    &__header {
        text-align: center;
        &__catch {
            color: $txt-primary-dark;
            font-size: 1.7rem;
            font-weight: bold;
            @include bp-down(md) {
                font-size: 1.4rem;
            }
        }
        &__ttl {
            font-size: 3rem;
            font-size: 2.4rem;
        }
    }
    &__content {
        max-width: 780px;
        // @include lay-content-inner;
        @include zero-auto(4rem, 0);
        display: flex;
        justify-content: space-between;
        @include bp-down(md) {
            flex-wrap: wrap;
            justify-content: center;
            padding: 0 15px 20px;
        }
        &__txt {
            max-width: 500px;
            p {
                line-height: 1.8;
                & + p {
                    margin-top: 2rem;
                }
            }
        }
        &__intro {
            max-width: 240px;
            @include bp-down(md) {
                margin-top: 2rem;
            }
            img {
                border: 1px solid #ccc;
            }
            p {
                font-size: 1.3rem;
                text-align: right;
                margin-top: 2rem;
            }
        }
    }
}
.housingloan-type {
    margin: 7rem 0;
    &__ttl {
        font-size: 3rem;
        text-align: center;
        @include bp-down(md) {
            font-size: 2.4rem;
        }
    }
    &__catch {
        text-align: center;
        margin-top: 3.5rem;
    }
    &__graph {
        @include lay-content-inner;
        @include zero-auto(4rem, 0);
        &__list {
            display: flex;
            justify-content: space-between;
            border: 1px solid #ccc;
            @include bp-down(md) {
                flex-wrap: wrap;
            }
            &__item {
                width: calc(100% / 3);
                padding: 4rem;
                background-color: #fff;
                @include bp-down(md) {
                    width: 100%;
                    padding: 3rem;
                }
                h3 {
                    text-align: center;
                    color: $txt-primary-dark;
                    font-size: 2rem;
                    margin-bottom: 2rem;
                }
                p {
                    font-weight: bold;
                    margin-top: 2rem;
                }
                &:nth-child(odd) {
                    background-color: $bg-muted-light;
                }
                & + li {
                    border-left: 1px solid #ccc;
                    @include bp-down(md) {
                        border-left: none;
                        border-top: 1px solid #ccc;
                    }
                }
            }
        }
        &--cap {
            font-size: 1.2rem;
            margin-top: 1.5rem;
        }
    }
}
.housingloan-flat35 {
    background-color: #fff;
    padding: 7rem 0;
    &__inner {
        @include lay-content-inner;
        @include zero-auto();
    }
    h2 {
        text-align: center;
    }
    &__sub {
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
        margin-top: 2rem;
        @include bp-down(md) {
            font-size: 2.4rem;
        }
    }
    &__catch {
        text-align: center;
        margin-top: 3rem;
        line-height: 1.8;
        @include bp-down(md) {
            text-align: left;
        }
    }
}
.inner-page-link-dot03 {
    @include lay-content-inner;
    @include zero-auto(4rem, 0);
    &__set {
        display: flex;
        justify-content: space-between;
        @include bp-down(md) {
            flex-wrap: wrap;
        }
        &__item {
            width: 23%;
            position: relative;
            @include bp-down(md) {
                width: 100%;
                & + li {
                    margin-top: 4rem;
                }
            }
            &__point {
                color: #fff;
                background-color: $bg-primary;
                text-align: center;
                font-size: 1.5rem;
                width: 100px;
                position: absolute;
                top: -1.2rem;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 2;
                padding: 0.2rem 0;
                border-radius: 16px;
            }
            &__link {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                border: 2px dotted $border-primary;
                border-radius: 10px;
                // display: block;
                color: $txt-primary-dark;
                text-align: center;
                padding: 2.5rem 0 2rem 0;
                font-weight: bold;
                height: 145px;
                &:hover {
                    color: $txt-primary-dark;
                    opacity: 0.8;
                }
                &__catch {
                    font-size: 1.7rem;
                }
            }
        }
    }
}
.transition {
    background-color: #4F81BD;
    padding: 7rem 0;
    &__inner {
        max-width: 800px;
        @include zero-auto(3.5rem,0);
        @include bp-down(md) {
            >img {
                width: 90%;
                margin: 0 auto;
                display: block;
            }
        }
    }
    &__ttl {
        color: #fff;
        text-align: center;
    }
    &__cap {
        background-color: #6E9BD1;
        color: #fff;
        text-align: center;
        padding: 1.5rem 0;
        font-size: 1.3rem;
        margin-top: 2rem;
        @include bp-down(md) {
            padding: 1rem;
        }
    }
    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 3rem;
        @include bp-down(md) {
            width: 90%;
            margin: 2rem auto 0 auto;
        }
        &__item {
            width: 48%;
            margin-bottom: 2rem;
            @include bp-down(md) {
                width: 100%;
                margin-bottom: 0;
                & + li {
                    margin-top: 1rem;
                }
            }
            &__link {
                font-weight: bold;
                text-align: center;
                display: block;
                line-height: 1;
                img {
                    margin-right: 1rem;
                }
            }
        }
    }
}
.house-flow {
    padding: 7rem 0;
    text-align: center;
    &__inner {
        @include lay-content-inner;
        @include zero-auto();
    }
    &__sub {
        color: $txt-primary-dark;
        font-size: 1.7rem;
        font-weight: bold;
    }
    &__ttl {
        font-size: 3rem;
    }
    &__table {
        margin-top: 3rem;
        @include bp-down(md) {
            overflow-x: scroll;
            >img {
                display: block;
                width: 600px;
                max-width: 600px;
                height: auto;
            }
        }
    }
}
.thumb02 {
    img {
        width: 100%;
        @include bp-down(md) {
            object-fit: cover;
            height: 200px;
        }
    }
}
.mynote {
    @include lay-content-inner;
    @include zero-auto(-15rem, 0);
    position: relative;
    background-color: #F0F2D5;
    padding: 5rem 0;
    border-top: 3px solid $border-primary-dark;
    @include bp-down(md) {
        margin-top: -3rem;
        padding: 3rem 1rem;
    }
    &__inner {
        max-width: 450px;
        @include zero-auto();
        text-align: center;
    }
    &__ttl {
        color: $txt-primary-dark;
        font-size: 3rem;
        @include bp-down(md) {
            font-size: 2.2rem;
        }
    }
    &__sub {
        font-size: 1.7rem;
        font-weight: bold;
    }
    &__cap {
        font-size: 1rem;
        margin-top: 1rem;
    }
    .flat-link {
        &:hover img {
            opacity: .7;
        }
    }
    img {
        display: block;
        border: 2px solid $border-primary-dark;
        margin-top: 3.5rem;
    }
}
