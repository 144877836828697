@use "../mixins" as *;
@use "../variables" as *;

.contents--main--recruit {
    padding: 7rem 0 13rem 0;
    background-color: $bg-primary-lighter;
    @include bp-down(md) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}
.rec-contact-btn {
    display: block;
    color: #fff;
    width: 300px;
    @include zero-auto(4rem,0);
    text-align: center;
    font-weight: bold;
    &.btn-margin-adjust {
        margin-top: 7rem;
        @include bp-down(md) {
            margin-top: 3rem;
        }
    }
}
.ill-thumb-set__list {
    display: flex;
    margin-top: 7rem;
    &__item {
        width: 25%;
        img {
            width: 100%;
        }
    }
}
.tab-menu__contents--recruit-flow {
    margin-top: 5rem;
    @include bp-down(md) {
        margin-top: 4rem;
    }
}
.recruit-flow {
    padding: 7rem 0 7rem 0;
    background-color: #fff;
    @include bp-down(md) {
        padding: 3rem 0;
    }
    &__ttl {
        font-size: 3rem;
        text-align: center;
        @include bp-down(md) {
            font-size: 2rem;
        }
    }
    &__list {
        max-width: 800px;
        @include zero-auto;
        &__item {
            position: relative;
            &:before {
                position: absolute;
                content: "";
                width: 2rem;
                height: 2rem;
                border-radius: 1rem;
                border: 4px solid $border-primary;
                top: 0;
                left: 0;
                background-color: #fff;
                z-index: 2;
            }
            &:after {
                position: absolute;
                content: "";
                width: 4px;
                height: 120%;
                top: 0;
                left: .8rem;
                background-color: $bg-primary;
                z-index: 1;
            }
            &:nth-child(7) {
                @include bp-down(md) {
                    &:after {
                        height: 110%;
                    }
                }
            }
            &:last-child {
                &:after {
                    content: none;
                }
            }
            & + li {
                margin-top: 2rem;
            }
            &__ttl {
                font-size: 1.7rem;
                color: $txt-primary-dark;
            }
            &__catch {
                margin-top: 1.5rem;
            }
            &__time {
                font-size: 2rem;
                font-weight: bold;
                padding-left: 4.5rem;
                line-height: 1;
            }
            &__txt {
                width: 700px;
                display: flex;
                justify-content: space-between;
                margin-left: auto;
                margin-top: 1rem;
                background-color: $bg-primary-lighter;
                @include bp-down(md) {
                    margin-left: 4.5rem;
                    width: calc(100% - 4.5rem);
                    flex-wrap: wrap;
                }
                img {
                    border: 1px solid #ccc;
                    height: 133px;
                    @include bp-down(md) {
                        height: auto;
                        width: 100%;
                    }
                }
                &__inner {
                    padding: 2.5rem 3rem;
                }
            }
        }
    }
}
