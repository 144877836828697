// ---------------------------------------------
//   media query
//----------------------------------------------
$retina: "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)";

//hover
$hover-opacity-ratio: 0.8;
$hover-opacity-ms: 300;

//fixed header height
$fixed-header-height-pc: 0;
$fixed-header-height-tb: 0;
$fixed-header-height-sp: 0;

