@use "../mixins" as *;
@use "../variables" as *;

/* ---------------------------------------------
*   sitemap
--------------------------------------------- */
.sitemap {
    background-color: $bg-primary-lighter;

    &__container {
        max-width: $width-base;
        margin: 0 auto;
        padding: 85px 0 65px;
        box-sizing: border-box;

        @include bp-down(md) {
            width: $width-base-sp;
            padding: 4rem 0;
        }
    }

    &__contents {
        display: flex;
        justify-content: space-between;

        @include bp-down(md) {
            display: block;
        }
    }

    &__list {
        width: 30%;
        // margin-left: 30px;

        @include bp-down(md) {
            width: 100%;
            margin: 1rem auto 0 auto;

        }

        &:first-of-type {
            margin-left: 0;
        }
    }

    &__item {
        & + li {
            margin-top: 1rem;
        }
    }

    &__link {
        position: relative;
        display: block;
        padding: 1.42rem 0 1.67rem 3rem;
        font-weight: bold;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #ccc;
        @include hover-opacity();
        @include ie {
            padding: 1.6rem 0 1rem 3rem;
        }
        @include bp-down(md) {
            padding: 1.4rem 1.4rem 1.4rem 2.4rem;
            font-size: 1.4rem;
        }

        &:after {
            content: "";
            position: absolute;
            top: 40%;
            right: 2rem;
            width: 10px;
            height: 10px;
            border-top: 2px solid $border-primary;
            border-right: 2px solid $border-primary;
            transform: rotate(45deg);

            @include bp-down(md) {
                right: 1rem;
            }
        }
    }
}
.sitemap__list-second {
    &__item {
        width: 90%;
        margin-left: auto;
        margin-top: 1rem;
        @include bp-down(md) {
            width: 90%;
        }
        &__link {
            display: block;
            background-color: #f0f2d5;
            font-size: 1.3rem;
            padding: 1.67rem 0 1.73rem 3rem;
            border-radius: 5px;
            position: relative;
            border: 1px solid #ccc;
            @include ie {
                padding: 1.6rem 0 1rem 3rem;
            }
            @include bp-down(md) {
                padding: 1.4rem 1.4rem 1.4rem 2.4rem;
                font-size: 1.4rem;
            }
            &:after {
                content: "";
                position: absolute;
                top: 40%;
                right: 2rem;
                width: 10px;
                height: 10px;
                border-top: 2px solid $border-primary;
                border-right: 2px solid $border-primary;
                transform: rotate(45deg);
                @include bp-down(md) {
                    right: 1rem;
                }
            }
        }
    }
}
