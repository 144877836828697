@use "../variables" as *;

/// hover-opacity
/// 汎用的に使用するホバースタイル
@mixin hover-opacity($ms: $hover-opacity-ms, $ratio: $hover-opacity-ratio) {
    transition: opacity $ms * 1ms;

    &:hover {
        opacity: $ratio;
    }
}

/// hover-opacity-touch-device-disabled
/// 汎用的に使用するホバースタイルで、iosおよびandroidの場合にホバー効果をなしにする
@mixin hover-opacity-touch-device-disabled($ms: $hover-opacity-ms, $ratio: $hover-opacity-ratio, $original-ratio: 1) {
    transition: opacity $ms * 1ms;

    &:hover {
        opacity: $ratio;
    }

    @at-root {
        .ios &:hover,
        .android &:hover {
            opacity: $original-ratio;
        }
    }
}

/// hover-opacity-ios-disabled
/// 汎用的に使用するホバースタイルで、iosの場合にホバー効果をなしにする
@mixin hover-opacity-ios-disabled($ms: $hover-opacity-ms, $ratio: $hover-opacity-ratio, $original-ratio: 1) {
    transition: opacity $ms * 1ms;

    &:hover {
        opacity: $ratio;
    }

    @at-root {
        .ios &:hover {
            opacity: $original-ratio;
        }
    }
}

/// hover-opacity-delegate
/// 汎用的に使用するホバースタイルで、先祖要素がホバーした場合にホバースタイルが有効になる
@mixin hover-opacity-delegate($selector, $ms: $hover-opacity-ms, $ratio: $hover-opacity-ratio) {
    transition: opacity $ms * 1ms;

    #{$selector}:hover & {
        opacity: $ratio;
    }
}

/// hover-opacity-touch-device-disabled-delegate
/// 汎用的に使用するホバースタイルで、先祖要素がホバーした場合にホバースタイルが有効になる
/// iosおよびandroidの場合にホバー効果をなしにする
@mixin hover-opacity-touch-device-disabled-delegate($selector, $ms: $hover-opacity-ms, $ratio: $hover-opacity-ratio, $original-ratio: 1) {
    transition: opacity $ms * 1ms;

    #{$selector}:hover & {
        opacity: $ratio;
    }

    @at-root {
        .ios #{$selector}:hover &,
        .android #{$selector}:hover & {
            opacity: $original-ratio;
        }
    }
}

/// hover-opacity-ios-disabled-delegate
/// 汎用的に使用するホバースタイルで、先祖要素がホバーした場合にホバースタイルが有効になる
/// iosの場合にホバー効果をなしにする
@mixin hover-opacity-ios-disabled-delegate($selector, $ms: $hover-opacity-ms, $ratio: $hover-opacity-ratio, $original-ratio: 1) {
    transition: opacity $ms * 1ms;

    #{$selector}:hover & {
        opacity: $ratio;
    }

    @at-root {
        .ios #{$selector}:hover & {
            opacity: $original-ratio;
        }
    }
}

/// hover-underline
/// 汎用的に使用するホバースタイル
/// ホバー時に下線を表示する
@mixin hover-underline() {

    &:hover {
        text-decoration: underline;
    }
}

/// hover-underline-touch-device-disabled
/// 汎用的に使用するホバースタイル
/// ホバー時に下線を表示する
/// タッチデバイスの場合、スタイルを無効にする
@mixin hover-underline-touch-device-disabled() {

    &:hover {
        text-decoration: underline;
    }

    @at-root {
        .ios &:hover,
        .android &:hover {
            text-decoration: none;
        }
    }
}

/// hover-underline-ios-disabled
/// 汎用的に使用するホバースタイル
/// ホバー時に下線を表示する
/// iOSデバイスの場合、スタイルを無効にする
@mixin hover-underline-ios-disabled() {

    &:hover {
        text-decoration: underline;
    }

    @at-root {
        .ios &:hover {
            text-decoration: none;
        }
    }
}

/// hover-underline-delegate
/// 汎用的に使用するホバースタイル
/// 先祖要素がホバーした場合に下線を表示する
@mixin hover-underline-delegate($selector) {

    #{$selector}:hover & {
        text-decoration: underline;
    }
}

/// hover-underline-delegate-touch-device-disabled
/// 汎用的に使用するホバースタイル
/// 先祖要素がホバーした場合に下線を表示する
/// タッチデバイスの場合、スタイルを無効にする
@mixin hover-underline-touch-device-disabled-delegate($selector) {

    #{$selector}:hover & {
        text-decoration: underline;
    }

    @at-root {
        .ios #{$selector}:hover &,
        .android #{$selector}:hover & {
            text-decoration: none;
        }
    }
}

/// hover-underline-delegate-ios-disabled
/// 汎用的に使用するホバースタイル
/// 先祖要素がホバーした場合に下線を表示する
/// iOSデバイスの場合、スタイルを無効にする
@mixin hover-underline-ios-disabled-delegate($selector) {

    #{$selector}:hover & {
        text-decoration: underline;
    }

    @at-root {
        .ios #{$selector}:hover & {
            text-decoration: none;
        }
    }
}

/// hover-underline-none
/// 汎用的に使用するホバースタイル
/// 通常時、下線を表示し、ホバー時に下線を非表示にする
@mixin hover-underline-none() {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

/// hover-underline-none-touch-device-disabled
/// 汎用的に使用するホバースタイル
/// 通常時、下線を表示し、ホバー時に下線を非表示にする
/// タッチデバイスの場合、スタイルを無効にする
@mixin hover-underline-none-touch-device-disabled() {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

    @at-root {
        .ios &:hover,
        .android &:hover {
            text-decoration: underline;
        }
    }
}

/// hover-underline-none-ios-disabled
/// 汎用的に使用するホバースタイル
/// 通常時、下線を表示し、ホバー時に下線を非表示にする
/// iOSデバイスの場合、スタイルを無効にする
@mixin hover-underline-none-ios-disabled() {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

    @at-root {
        .ios &:hover {
            text-decoration: underline;
        }
    }
}

/// hover-underline-none-delegate
/// 汎用的に使用するホバースタイル
/// 先祖要素がホバーした場合に下線を非表示にする
@mixin hover-underline-none-delegate($selector) {
    text-decoration: underline;

    #{$selector}:hover & {
        text-decoration: none;
    }
}

/// hover-underline-none-delegate-touch-device-disabled
/// 汎用的に使用するホバースタイル
/// 先祖要素がホバーした場合に下線を非表示にする
/// タッチデバイスの場合、スタイルを無効にする
@mixin hover-underline-none-touch-device-disabled-delegate($selector) {
    text-decoration: underline;

    #{$selector}:hover & {
        text-decoration: none;
    }

    @at-root {
        .ios #{$selector}:hover &,
        .android #{$selector}:hover & {
            text-decoration: underline;
        }
    }
}

/// hover-underline-none-delegate-ios-disabled
/// 汎用的に使用するホバースタイル
/// 先祖要素がホバーした場合に下線を非表示にする
/// iOSデバイスの場合、スタイルを無効にする
@mixin hover-underline-none-ios-disabled-delegate($selector) {
    text-decoration: underline;

    #{$selector}:hover & {
        text-decoration: none;
    }

    @at-root {
        .ios #{$selector}:hover & {
            text-decoration: underline;
        }
    }
}
