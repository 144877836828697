@use "../variables" as *;
@use "mediaquery" as *;

@mixin goth {
    font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", 'Hiragino Kaku Gothic ProN', 'meiryo', sans-serif, 'icons';
}
@mixin robo {
    font-family: "Roboto", sans-serif;
}
@mixin robocon {
    font-family: "Roboto Condensed", sans-serif;
}
@mixin childHead($child-ttl-pc: 4rem, $child-ttl-sp: 2.4rem) {
    font-size: $child-ttl-pc;
    @include bp-down(md) {
        font-size: $child-ttl-sp;
    }
}
@mixin childConte($child-ttl-pc: 3rem, $child-ttl-sp: 1.8rem) {
    font-size: $child-ttl-pc;
    @include bp-down(md) {
        font-size: $child-ttl-sp;
    }
}
