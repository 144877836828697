//   design width
//----------------------------------------------
$design-width-pc: 1200;
// $design-width-tb: 900;
$design-width-sp: 375;

$contents-width-pc: 1180;
// $contents-width-tb: 880;
$contents-width-sp: 355;

//   contents side padding
//----------------------------------------------
$contents-side-padding-pc: 10;
// $contents-side-padding-tb: 10;
$contents-side-padding-sp: 10;

//   min width
//----------------------------------------------
$minwidth-pc: $contents-width-pc + ($contents-side-padding-pc * 2);
// $minwidth-tb: $design-width-sp + 1;
$minwidth-sp: 320;

//width
$width-max: 1200px;
$width-base: 960px;
$width-base-sp: 90%;