@use "../mixins" as *;
@use "../variables" as *;

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.contents--main--news {
    background-color: $bg-primary-lighter;
    padding: 7rem 0 13rem 0;
    @include bp-down(md) {
        padding: 3rem 0 4rem 0;
    }
}
.news--main {
    @include lay-content-inner;
    @include zero-auto;
}
.news-ttl {
    font-size: 3rem;
    text-align: center;
    @include bp-down(md) {
        font-size: 2rem;
    }
}
.pankuzu_box {
    position: relative;
}
.pankuzu__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.news__container {
    max-width: 1000px;
    width: 96%;
    margin: 0 auto;
}
.blog-detail {
    @include lay-content-inner;
    @include zero-auto();
    background-color: #fff;
    padding: 5rem 10rem;
    @include bp-down(md) {
        padding: 2rem;
    }
    &__head {
        display: flex;
    }
    &__date {
        @include robocon;
        font-weight: bold;
        padding-right: 1rem;
        line-height: 1;
    }
    &__cat {
        font-weight: bold;
        font-size: 1.5rem;
        color: $txt-primary-dark;
        padding-left: 1rem;
        border-left: 1px solid #ccc;
        line-height: 1;
    }
    &__ttl {
        font-size: 2.5rem;
        margin-top: 3rem;
        padding-bottom: 3rem;
        border-bottom: 2px solid $bg-muted-light;
    }
    &__contents {
        margin-top: 4rem;
    }
}
.news__contents {
    &__select {
        display: flex;
        justify-content: center;
        margin-top: 3.5rem;
        margin-bottom: 2rem;
        padding: 3rem 0;
        background-color: $bg-accent1-lightest;
        @include bp-down(md) {
            flex-direction: column;
            align-items: center;
            margin-top: 2rem;
        }
        &__box {
            width: 290px;
            justify-content: center;
            position: relative;
            border-radius: 3px;
            &:after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 5px 0 5px;
                border-color: #212a37 transparent transparent transparent;
                top: 23px;
                right: 20px;
            }
            & + div {
                margin-left: 1rem;
                @include bp-down(md) {
                    margin-left: 0;
                    margin-top: 2rem;
                }
            }
            select {
                width: 100%;
                padding: 0.5rem;
                border: 1px solid #ccc;
                padding: 1.5rem 2rem;
                appearance: none;
                border-radius: 3px;
                &::-ms-expand {
                    display: none;
                }
                &::-ms-value {
                    background: none;
                }
            }
        }
    }
}
$itemWidth: 32%;
.news__list {
    list-style-type: none;
    padding: 0;
    margin: 4rem auto 0 auto;
    border: 1px solid #ccc;
    &__item {
        & + li {
            border-top: 1px solid #ccc;
        }
        p {
            // margin: 0 0 0.5rem 0;
        }
        a {
            background-color: #fff;
            display: block;
            padding: 2.5rem 3rem;
        }
        &__link {
            position: relative;
            &:after {
                position: absolute;
                content: "";
                width: 10px;
                height: 10px;
                border-top: 2px solid $bg-muted-light;
                border-right: 2px solid $bg-muted-light;
                top: 44%;
                right: 20px;
                transform: rotate(45deg);
            }
            &__box {
                &__head {
                    display: flex;
                    align-items: center;
                    .news-cat {
                        color: $txt-primary;
                        font-size: 1.3rem;
                        padding-left: 1.5rem;
                        line-height: 1;
                        font-size: 1.3rem;
                    }
                    .news-date {
                        padding-right: 1.5rem;
                        border-right: 1px solid #ccc;
                        line-height: 1;
                        font-size: 1.3rem;
                    }
                }
                &__ttl {
                    font-weight: bold;
                    margin-top: 1.5rem;
                }
            }
        }
    }
}
.news-detail {
    box-sizing: border-box;

    @include bp-down(md) {
        margin-top: pxtovw(30, "sp");
        border-radius: pxtovw(10, "sp");
    }

    &__container {
        @include bp-down(md) {
            padding: pxtovw(20, "sp");
        }
    }

    &__head {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $bg-muted-light;

        @include bp-down(md) {
            padding-bottom: pxtovw(10, "sp");
            justify-content: space-between;
        }
    }

    &__date {
        // font-size: pxtorem(14, "pc");
        font-weight: bold;
        color: $txt-primary;

        @include bp-down(md) {
            font-size: pxtorem(14, "sp");
        }
    }

    &__cat {
        margin-left: 50px;
        padding: 1px 22px;
        // font-size: pxtorem(12, "pc");
        font-weight: bold;
        color: $txt-light;
        background-color: $bg-muted;
        border-radius: 10px;

        @include bp-down(md) {
            margin-left: auto;
            padding: pxtovw(1, "sp") pxtovw(22, "sp");
            font-size: pxtorem(12, "sp");
            border-radius: pxtovw(10, "sp");
        }
    }

    &__ttl {
        margin-top: 15px;
        // font-size: pxtorem(20, "pc");
        font-weight: bold;

        @include bp-down(md) {
            margin-top: pxtovw(10, "sp");
            font-size: pxtorem(20, "sp");
        }
    }

    &__contents {
        margin-top: 35px;

        @include bp-down(md) {
            margin-top: pxtovw(20, "sp");
        }

        img {
            width: 100%;
            display: block;
        }
        > h1 {
            font-size: 1.6rem;
        }
        > h2 {
            margin-top: 35px;
            font-size: 1.4rem;
        }
        > h3 {
            font-size: 1.2rem;
        }
        > h4 {
            font-size: 1rem;
        }
        p {
            margin-top: 35px;
            // font-size: pxtorem(14, "pc");

            @include bp-down(md) {
                margin-top: pxtovw(20, "sp");
                font-size: pxtorem(14, "sp");
            }
        }
        > ul {
            margin-top: 35px;
            > li {
                position: relative;
                padding-left: 1rem;
                &:before {
                    position: absolute;
                    content: "・";
                    top: 0;
                    left: -4px;
                }
            }
            @include bp-down(md) {
                margin-top: pxtovw(20, "sp");
            }
        }
        > ol {
            margin-top: 35px;
            counter-reset: number;
            list-style-position: inside;
        }
        > table {
            margin-top: 35px;
            border-spacing: 1;
            border-collapse: unset;
            th,
            td {
                padding: 10px;
                vertical-align: middle;
            }
        }
    }

    &__sns {
        margin-top: 40px;

        @include bp-down(md) {
            margin-top: pxtovw(40, "sp");
        }
    }
}

.blog-link {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include bp-down(md) {
        flex-direction: column;
    }
    &__btn {
        a {
            color: #333;
            padding: 1.5rem 0;
            display: block;
            font-weight: bold;
            font-size: 1.7rem;
            position: relative;
            &:hover {
                opacity: .7;
            }
        }
        & + div {
            margin-left: 1rem;
            @include bp-down(md) {
                margin-left: 0;
                margin-top: 2rem;
            }
        }
        &.prev-btn {
            a {
                width: 250px;
                &:before {
                    position: absolute;
                    content: "";
                    width: 17px;
                    height: 17px;
                    background-image: url(../images/common/arrow-bg-green.svg);
                    background-size: cover;
                    top: 20px;
                    left: 20px;
                    transform: rotate(180deg);
                }
            }
        }
        &.next-btn {
            a {
                width: 250px;
                &:after {
                    position: absolute;
                    content: "";
                    width: 17px;
                    height: 17px;
                    background-image: url(../images/common/arrow-bg-green.svg);
                    background-size: cover;
                    top: 20px;
                    right: 20px;
                }
            }
        }
        &.list-btn {
            a {
                width: 200px;
                @include bp-down(md) {
                    width: 250px;
                }
            }
        }
    }

    .mod-btn {
        margin-left: 20px;

        @include bp-down(md) {
            margin-top: pxtovw(15, "sp");
            margin-left: 0;
        }

        &:first-of-type {
            margin-left: 0;

            @include bp-down(md) {
                margin-top: 0;
            }
        }
    }
}

.entry_comment {
    margin-top: 4rem;
    .entry_comment_body {
        margin: 0 auto;
        background: #ecf9f3;
        padding: 10px;
    }
}
body.error404 {
    .news__contents {
        margin-bottom: 20px;
        .erro-txt {
            text-align: center;
        }
    }
}
body.single {
    .news__container {
        padding-top: 60px;
        @include bp-down(md) {
            padding-top: 30px;
        }
        .img-div {
            margin-top: 35px;
        }
    }
}
