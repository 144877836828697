@use "../mixins" as *;
@use "../variables" as *;

// Common
// -----------------------------------------------------------------------------
@for $i from 0 through 20 {
    @if $i == 0 {
        .mt--0 {
            margin-top: 0 !important;
        }
        .mr--0 {
            margin-right: 0 !important;
        }
        .mb--0 {
            margin-bottom: 0 !important;
        }
        .ml--0 {
            margin-left: 0 !important;
        }
    }
    @else {
        .mt--#{$i * 5} {
            margin-top:#{$i * 5}px !important;
        }
        .mr--#{$i * 5} {
            margin-right:#{$i * 5}px !important;
        }
        .mb--#{$i * 5} {
            margin-bottom:#{$i * 5}px !important;
        }
        .ml--#{$i * 5} {
            margin-left:#{$i * 5}px !important;
        }
    }
}

// Middle Range
// -----------------------------------------------------------------------------
@include bp-up(md) {
    @for $i from 0 through 20 {
        @if $i == 0 {
            .mt-pc--0 {
                margin-top: 0 !important;
            }
            .mr-pc--0 {
                margin-right: 0 !important;
            }
            .mb-pc--0 {
                margin-bottom: 0 !important;
            }
            .ml-pc--0 {
                margin-left: 0 !important;
            }
        }
        @else {
            .mt-pc--#{$i * 5} {
                margin-top:#{$i * 5}px !important;
            }
            .mr-pc--#{$i * 5} {
                margin-right:#{$i * 5}px !important;
            }
            .mb-pc--#{$i * 5} {
                margin-bottom:#{$i * 5}px !important;
            }
            .ml-pc--#{$i * 5} {
                margin-left:#{$i * 5}px !important;
            }
        }
    }
}

// Small Range
// -----------------------------------------------------------------------------
@include bp-down(md) {
    @for $i from 0 through 20 {
        @if $i == 0 {
            .mt-sp--0 {
                margin-top: 0 !important;
            }
            .mr-sp--0 {
                margin-right: 0 !important;
            }
            .mb-sp--0 {
                margin-bottom: 0 !important;
            }
            .ml-sp--0 {
                margin-left: 0 !important;
            }
        }
        @else {
            .mt-sp--#{$i * 5} {
                margin-top:#{$i * 5}px !important;
            }
            .mr-sp--#{$i * 5} {
                margin-right:#{$i * 5}px !important;
            }
            .mb-sp--#{$i * 5} {
                margin-bottom:#{$i * 5}px !important;
            }
            .ml-sp--#{$i * 5} {
                margin-left:#{$i * 5}px !important;
            }
        }
    }
}
