@use "../mixins" as *;
@use "../variables" as *;

/* ---------------------------------------------
*   button
--------------------------------------------- */

.mod-btn {
    border-radius: 5px;
    padding: 1.3rem 0;
    text-align: center;
    &:hover {
        opacity: .7;
        color: #fff;
    }
    &--green {
        background-image: url(/assets/images/common/bg-green.png);
        &:hover {
            color: #fff;
            opacity: .7;
        }
    }
    &--gray {
        background-image: url(/assets/images/common/bg-gray.png);
        &:hover {
            color: #2d2d2d;
        }
    }
    &--base {
        background-image: url(/assets/images/common/bg-green.png);
        color: #fff;
        font-weight: bold;
        display: block;
        font-size: $font-size-default;
        padding: 1.5rem 0;
        width: 250px;
        border-radius: 5px;
        &:hover {
            color: #fff;
            opacity: .7;
        }
    }
}
.mod-btn-page {
    border-radius: 5px;
    padding: 1.3rem 0;
    text-align: center;
    color: #fff;
    font-weight: bold;
    display: block;
    font-size: $font-size-default;
    width: 250px;
    border-radius: 5px;
    @include ie {
        padding: 1.6rem 0 1rem 0;
    }
    &:hover {
        opacity: .7;
        color: #fff;
    }
    &--green {
        background-image: url(/assets/images/common/bg-green.png);
    }
    &--gray {
        background-image: url(/assets/images/common/bg-gray.png);
    }
}
