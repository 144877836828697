// =============================================================================
// プロジェクト上で利用しない設定は、必ずコメントアウトしてください
// Base Colors, Theme Colors, Utility Colors, Grayscales の変数値は、CSSでのカラー指定で利用しないでください
// =============================================================================

// Base Colors
// -----------------------------------------------------------------------------
// プロジェクトのカラー設定に合わせて、色味を変更してください
$white:                     #ffffff !default;
$black:                     #000000 !default;
$red:                       #E80C31 !default;
$pink:                      #E53263 !default;
$orange:                    #F57D06 !default;
$yellow:                    #FFFF00 !default;
// $lemon:                     #F1CA08 !default;
$lightgreen:                #B0D031 !default;
$green:                     #83A207 !default;
$turquoise:                 #0d90af !default;
$lightblue:                 #0F91E8 !default;
$blue:                      #0946d0 !default;
$deepblue:                  #0b0b62 !default;
// $purple:                    #664278 !default;
// $gold:                      #8e7a0d !default;


// Theme Colors
// -----------------------------------------------------------------------------
// プロジェクトのカラー設定に合わせて、適宜調整（追加・削除）してください
$theme-primary:             $lightgreen;
$theme-accent1:             $yellow;
$theme-accent2:             $pink;
// $theme-accent3:             $pink;


// Utility Colors
// -----------------------------------------------------------------------------
$info:                      $turquoise;
$success:                   $lightblue;
$warning:                   $orange;
$danger:                    $red;


// Grayscales
// -----------------------------------------------------------------------------
// プロジェクトのカラー設定に合わせて、色味を変更してください
$gray-lightest:             #f6f6f6 !default;
$gray-lighter:              #cccccc !default;
$gray-light:                #aaaaaa !default;
$gray:                      #888888 !default;
$gray-dark:                 #666666 !default;
$gray-darker:               #444444 !default;
// $gray-darkest:              #222222 !default;


// =============================================================================
// ・CSSでカラー指定をする場合は、ここから下にある変数値を使ってください
// ・プロジェクト上で利用しない設定は、必ずコメントアウトしてください
// =============================================================================

// Text
// -----------------------------------------------------------------------------
$txt-default:               $black;
$txt-primary:               $theme-primary;
$txt-primary-dark:          $green;
$txt-accent1:               $theme-accent1;
$txt-accent2:               $theme-accent2;
// $txt-accent3:               $theme-accent3;
$txt-info:                  $info;
$txt-success:               $success;
$txt-warning:               $warning;
$txt-danger:                $danger;
$txt-light:                 $white;
$txt-dark:                  $black;
$txt-muted:                 $gray-dark;
$txt-note:                  $gray-light;


// Border
// -----------------------------------------------------------------------------
$border-default:            $gray-lighter;
$border-primary:            $theme-primary;
$border-primary-dark:       $green;
$border-accent1:            $theme-accent1;
$border-accent2:            $theme-accent2;
$border-info:               $info;
$border-success:            $success;
$border-warning:            $warning;
$border-danger:             $danger;
$border-light:              $white;
$border-dark:               $black;
$border-muted:              $gray;
$border-muted-light:        $gray-lightest;


// Background
// -----------------------------------------------------------------------------
$bg-primary:                $theme-primary;
$bg-primary-light:          #f0f2d5;
$bg-primary-lighter:        #f7f8ec;
// $bg-primary-lightest:       #edfafd;
$bg-primary-dark:           $green;
// $bg-primary-darker:         $;
// $bg-primary-darkest:        $;

$bg-accent1:                $theme-accent1;
// $bg-accent1-light:          ;
// $bg-accent1-lighter:        $;
$bg-accent1-lightest:       #F0F2D5;
// $bg-accent1-dark:           $;
// $bg-accent1-darker:         $;
// $bg-accent1-darkest:        $;

$bg-accent2:                $theme-accent2;
// $bg-accent2-light:          $;
// $bg-accent2-lighter:        $;
// $bg-accent2-lightest:       $;
// $bg-accent2-dark:           $;
// $bg-accent2-darker:         $;
// $bg-accent2-darkest:        $;

// $bg-accent3:                $theme-accent3;
// $bg-accent3-light:          $;
// $bg-accent3-lighter:        $;
// $bg-accent3-lightest:       $;
// $bg-accent3-dark:           $;
// $bg-accent3-darker:         $;
// $bg-accent3-darkest:        $;

$bg-info:                   $info;
$bg-info-light:             #edf6e0;

$bg-success:                $success;
$bg-success-light:          #e4f3fd;

$bg-warning:                $warning;
$bg-warning-light:          #fef3e0;

$bg-danger:                 $danger;
$bg-danger-light:           #feebee;

$bg-muted:                  $gray-lighter;
$bg-muted-light:            $gray-lightest;
$bg-muted-dark:             $gray;

$bg-light:                  $white;
$bg-dark:                   $black;
