@use "../mixins" as *;
@use "../variables" as *;

.contents--main--movies {
    padding: 7rem 0 13rem 0;
    background-color: $bg-primary-lighter;
    @include bp-down(md) {
        padding: 3rem 0 4rem 0;
    }
}
.mainvisual__movies {
    img {
        @include bp-down(md) {
            width: 300px;
            display: block;
            margin: 0 auto;
        }
    }
}
.movies-page-head-sub {
    text-align: center
}
.movies-content-wrap {
    max-width: 800px;
    @include zero-auto(4rem,0);
}
.movies__list {
    @include bp-down(md) {
        width: 90%;
        margin: 0 auto;
    }
    &__item {
        background-color: #fff;
        position: relative;
        & + li {
            margin-top: 2rem;
        }
        &.new {
            &:before {
                content: "NEW";
                @include robocon;
                color: #fff;
                position: absolute;
                top: 0;
                left: 0;
                width: 50px;
                height: 25px;
                text-align: center;
                padding-top: 4px;
                background-color: #E53263;
                font-size: 1.3rem;
            }
        }
        &__link {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            background-color: #fff;
            &.coming {
                pointer-events: none;
            }
            @include bp-down(md) {
                flex-wrap: wrap;
            }
            &__thumb-box {
                width: 300px;
                @include bp-down {
                    width: 100%;
                    img {
                        width: 100%;
                    }
                }
            }
            &__txt-box {
                width: 500px;
                padding: 3rem 2rem;
                position: relative;
                @include bp-down(md) {
                    padding: 2rem 1.5rem 4rem 1.5rem;
                }
                &__ttl {
                    font-size: 2rem;
                    @include bp-down(md) {
                        font-size: 1.4rem;
                    }
                }
                &__date {
                    font-size: 1.3rem;
                    @include robocon;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: 3rem;
                    left: 2rem;
                    @include bp-down(md) {
                        bottom: 1.5rem;
                    }
                    &__icon {
                        margin-right: .6rem;
                    }
                    >span {
                        display: block;
                    }
                }
            }
        }
    }
}
.movie-modal {
    iframe {
        width: 100%;
        height: 400px;
        vertical-align: bottom;
    }
}
