@use "../mixins" as *;
@use "../variables" as *;

/* ---------------------------------------------
*   pagenavi
--------------------------------------------- */
.blog__pagenavi {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
}
.wp-pagenavi {
    display: flex;
    border: 1px solid $border-muted-light;
    border-radius: 4px;
    background-color: #f7f8ec;
    justify-content: center;

    @include bp-down(md) {
        border-radius: pxtovw(4, "sp");
    }
    .pagination {
        display: flex;
        list-style-type: none;
        justify-content: center;
        padding: 0;
        margin: 5rem auto 0 auto;
        @include bp-down {
            margin-top: 3rem;
        }
        > li {
			& + li {
				margin-left: 1rem;
			}
            &.current {
                a {
                    color: #fff;
                    background-color: $theme-primary;
                }
            }
            &.previous, &.next {
                a {
                    color: $theme-primary;
                    font-size: 2.4rem;
                    @include bp-down {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
    a {
        border: 2px solid $theme-primary;
    }
    a,
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        // border-right: 1px solid $border-muted-light;
        // font-size: pxtorem(14, "pc");
        font-weight: bold;
        color: #000;
        background-color: $bg-light;
        transition-property: color, background-color;
        transition-duration: 0.3s, 0.3s;

        @include bp-down(md) {
            width: 35px;
            height: 35px;
            font-size: 1.4rem;
        }

        &:first-of-type {
            // border-radius: 4px 0 0 4px;

            @include bp-down(md) {
                border-radius: pxtovw(4, "sp") 0 0 pxtovw(4, "sp");
            }
        }

        &:last-of-type {
            // border: none;
            // border-radius: 0 4px 4px 0;

            @include bp-down(md) {
                border-radius: 0 pxtovw(4, "sp") pxtovw(4, "sp") 0;
            }
        }

        &.current {
            color: $txt-light;
            background-color: $bg-primary-dark;
        }

        &:hover {
            color: $txt-light;
            background-color: $bg-primary-dark;
        }
    }
}
