@use "../mixins" as *;
@use "../variables" as *;

.advantage {
    &--main {
        padding: 22rem 0 13rem 0;
        @include bp-down(md) {
            padding: 0 0 6rem 0;
        }
        position: relative;
        background-color: $bg-primary-lighter;
        .page-ttl--ttl--child {
            margin-top: 5rem;
            text-align: center;
            @include bp-down(md) {
                margin-top: 2rem;
                font-size: 1.8rem;
                text-align: left;
            }
        }
    }
    p {
        line-height: 2;
    }
}
.advantage-catch {
    max-width: 800px;
    @include zero-auto(4rem, 0);
    // line-height: 2;
    @include bp-down(md) {
        margin-top: 2rem;
    }
}
.link-set {
    display: flex;
    justify-content: center;
    align-items: center;
    @include lay-margin-top;
    @include bp-down(md) {
        flex-direction: column;
    }
    &__item {
        & + li {
            margin-left: 3rem;
            @include bp-down(md) {
                margin-top: 2rem;
                margin-left: 0;
            }
        }
    }
}
.page-head {
    background-color: #fff;
    position: relative;
    padding-top: 15rem;
    padding-bottom: 8rem;
    @include bp-down(md) {
        padding: 5rem 0 4rem 0;
    }
    .page-head-thumb {
        position: absolute;
        top: -150px;
        max-width: 1000px;
        padding: 0 20px;
        @include bp-down(md) {
            position: unset;
        }
        img {
            display: block;
            border-radius: $radius-default;
            @include bp-down(md) {
                object-fit: cover;
                height: 100px;
            }
        }
    }
}

.left-content-box {
    &--advantage {
        background-image: url(../images/advantage/advantage-suggestion-bg.png);
        background-color: $bg-primary-lighter;
    }
    &__wrapper {
        margin-right: auto;
        &__inner {
            .page-ttl {
                text-align: center;
                @include bp-down(md) {
                    font-size: 1.8rem;
                }
            }
            p {
                margin-top: 4rem;
                @include bp-down(md) {
                    margin-top: 2rem;
                }
            }
            width: 550px;
            margin-right: auto;
            background-color: #fff;
            padding: 6rem 5rem;
            .shop-link {
                margin: 4rem auto 0 auto;
                @include bp-down(md) {
                    margin: 2rem auto 0 auto;
                }
            }
            @include bp-down(md) {
                width: 100%;
                padding: 2rem;
            }
        }
    }
}
.online-consult {
    padding: 6rem 0 8rem 0;
    position: relative;
    background-color: #fff;
    @include bp-down(md) {
        padding: 3rem 0 3rem 0;
    }
    &__inner {
        @include lay-content-inner;
        @include zero-auto();
        .contact_link {
            @include zero-auto(4rem, 0);
            @include bp-down(md) {
                margin-top: 2rem;
                margin-bottom: 4rem;
            }
        }
    }
}
.online-consult-thumb-set {
    @include lay-content-inner;
    @include zero-auto(-3rem ,0);
    position: relative;
    z-index: 2;
    &__list {
        display: flex;
        justify-content: space-between;
        &__item {
            &:first-child {
                border-radius: $radius-default 0 0 $radius-default;
                img {
                    border-radius: $radius-default 0 0 $radius-default;
                }
            }
            &:last-child {
                border-radius: 0 $radius-default $radius-default 0;
                img {
                    border-radius: 0 $radius-default $radius-default 0;
                }
            }
            @include bp-down(md) {
                border-radius: .5rem;
                img {
                    border-radius: $radius-default;
                }
            }
        }
    }
}
