@use "../mixins" as *;
@use "../variables" as *;

/* ---------------------------------------------
*   header
--------------------------------------------- */
img {
    vertical-align: bottom;
}
.site-header {
    width: 300px;
    min-width: 260px;
    background-color: #fff;
    margin: 0 auto;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
    height: 100%;
    @include bp-down(md) {
        transform: translateY(100%);
        width: 100%;
        max-width: 100%;
        z-index: 9;
        transition: 0.5s;
        padding-bottom: 6rem;
        &.active {
            transform: translateY(0);
            transition: 0.5s;
        }
    }
}
.header__company-name {
    background-color: $bg-primary;
    font-size: 13px;
    text-align: center;
    padding: 1rem 0;
    color: #fff;
}
.header--logo {
    margin-top: 2.5rem;
    &__link {
        width: 190px;
        margin: 0 auto;
        display: block;
        text-align: center;
        @include hover-opacity();
    }
}
.header--top {
    width: 100%;
    @include bp-up(md) {
        max-width: 220px;
    }
}
.header--bottom {
    width: 100%;
    max-width: 220px;
    margin-top: 6vh;
    @include ie {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

/*  header-container
--------------------------------------------- */
.header-container {
    display: flex;
    flex-basis: auto;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 20px 80px 20px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
    @include ie {
        display: block;
        padding: 0 30px 160px 30px;
    }
}
/*  header-nav
--------------------------------------------- */
.header-nav {
    &__list {
        display: flex;
        align-items: center;
    }

    &__list-item {
        margin-left: 33px;

        &:first-of-type {
            margin-left: 0;
        }
    }

    &__link {
        position: relative;
        display: block;
        font-weight: bold;
        @include hover-opacity();

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -14px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 0 4px 6px;
            border-color: transparent transparent transparent $border-primary;
        }
    }
}
.header-info {
    width: 100%;
    .header-tel {
        display: flex;
        justify-content: flex-start;
        padding: 0 0 0.8rem 0;
        & + div {
            border-top: dashed 1px $border-default;
        }
        &:nth-child(2) {
            padding-top: 0.8rem;
        }
        &__box {
            margin-left: 1.4rem;
        }
        .area {
            width: 40px;
            height: 22px;
            text-align: center;
            padding: 0.5rem;
            font-size: 1.1rem;
            background-color: $bg-muted-light;
            border: 1px solid $border-default;
            border-radius: 3px;
            line-height: 1;
            font-weight: bold;
        }

        &__link {
            position: relative;
            display: block;
            font-size: 2.5rem;
            line-height: 1;
            @include robocon;
            .icon-phone-circled {
                color: $txt-primary;
                &:before {
                    margin-left: 0;
                }
            }
        }

        &__time {
            font-size: 1.1rem;
            font-weight: 500;
            margin-top: 0.3rem;
        }
    }
}

/*  header-contact
--------------------------------------------- */
.header-contact {
    width: 100%;
    margin-top: 1.3rem;
    &__link {
        position: relative;
        display: block;
        box-sizing: border-box;
        font-weight: bold;
        border-radius: 5px;
        color: #fff;
        text-align: center;
        padding: 1.2rem 0 1.2rem 3rem;
        @include ie {
            padding: 1.6rem 0 1rem 3rem;
        }
        &:hover {
            opacity: 0.7;
            color: #fff;
        }
        &:before {
            content: "";
            position: absolute;
            top: 36%;
            left: 50px;
            width: 20px;
            height: 14px;
            background-image: url(../images/common/icon-mail.svg);
            background-size: 100% 100%;
        }
    }
}
.header-accident {
    width: 100%;
    margin-top: 1.3rem;
    &__link {
        position: relative;
        display: block;
        box-sizing: border-box;
        font-weight: bold;
        border-radius: 5px;
        @include hover-opacity();
        color: #000;
        text-align: center;
        padding: 1.2rem 0 1.2rem 4rem;
        @include ie {
            padding: 1.6rem 0 1rem 4rem;
        }
        &:before {
            content: "";
            position: absolute;
            top: 34%;
            left: 50px;
            width: 17px;
            height: 15px;
            background-image: url(../images/common/icon-accident.svg);
            background-size: 100% 100%;
        }
    }
}

/* ---------------------------------------------
    SP Header
--------------------------------------------- */
.sp-header {
    &__comp-name {
        color: #fff;
        background-color: $bg-primary;
        text-align: center;
        font-size: 1.3rem;
        padding: 0.5rem 0;
    }
    &__logo {
        margin: 0 auto;
        width: 190px;
        padding: 2rem 0;
    }
    &--child {
        .sp-header__logo {
            width: 160px;
            padding: 1.5rem;
        }
        .sp-header__comp-name {
            font-size: 1.1rem;
        }
    }
}
.sp-menu-ttl {
    background-color: $bg-primary-dark;
    color: #fff;
    // text-align: center;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    font-weight: bold;
}
/* ---------------------------------------------
*   SP Fixed Navigation
--------------------------------------------- */
.fixed-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    width: 100%;
    box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.25);
    // box-shadow: 0 -2px 4px 0 rgba(221, 221, 221, 0.7);
    background-color: $bg-primary-dark;
    padding-top: 0.3rem;
    @at-root .js-sp-menu-opened & {
        box-shadow: none;
    }
    &__set {
        display: flex;
        width: calc(100% - 7rem);
        &__item {
            width: calc(100% / 4);
            border-right: 1px solid #ccc;
            background-color: #fff;
            padding: 1rem 0;
            img {
                display: block;
                margin: 0 auto 0.5rem auto;
            }
            span {
                display: block;
            }
            &--mail {
                img {
                    margin-top: 0.5rem;
                    margin-bottom: 0.7rem;
                }
            }
            &--accident {
                img {
                    margin-bottom: 0.9rem;
                }
            }
        }
    }
    &__link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
        background-color: #fff;
        font-size: 1.1rem;
        @include bp-down(md) {
            flex-direction: column;
        }
    }
    &-hamburger__btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7rem;
        padding: 0;
        border: 0;
        border-radius: 0;
        background-color: $bg-primary-dark;
        margin: 0;
        box-shadow: none;
        appearance: none;
        outline: none;
        cursor: pointer;
        box-sizing: border-box;

        &__lines {
            position: relative;
            display: block;
            width: 20px;
            height: 16px;
        }

        &__line {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 2px;

            &--top,
            &--center,
            &--bottom {
                transition: 0.5s;
            }

            &--top {
                top: 0;
                background-color: #fff;
            }

            &--center {
                top: 42%;
                background-color: #fff;
            }

            &--bottom {
                bottom: 0;
                background-color: #fff;
            }

            @at-root {
                .js-sp-menu-opened &--top {
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                }
                .js-sp-menu-opened &--center {
                    background-color: rgba(0, 0, 0, 0);
                }
                .js-sp-menu-opened &--bottom {
                    bottom: 50%;
                    transform: translateY(50%) rotate(-45deg);
                }
            }
        }
    }
}

/* ---------------------------------------------
*   global-nav
--------------------------------------------- */
.global-nav {
    width: 100%;
    margin-top: 2.5rem;
    &-primary {
        &__set {
            border-bottom: 2px solid $border-muted-light;
            &__item {
                height: 70px;
                &.home-link {
                    @include bp-down(md) {
                        border-top: 2px solid $border-muted-light;
                        height: 60px;
                        a {
                            padding-top: 1.8rem;
                            display: block;
                        }
                    }
                }
                & + li {
                    border-top: 2px solid $border-muted-light;
                }
            }
        }
        &__link {
            &--first {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1.4rem 0;
                &:hover {
                    @include hover-opacity;
                }
            }
        }
        &__txt {
            display: block;
            font-weight: bold;
            font-size: 1.7rem;
        }
        &__icon {
            display: block;
        }
    }
    &-secondary {
        &__set {
            margin: 1.5rem auto 0 auto;
            &__item {
                margin-top: 5px;
            }
        }
        &__link {
            background-color: $bg-primary-lighter;
            display: block;
            padding: 1rem 1rem 1rem 4rem;
            font-weight: bold;
            border-radius: 5px;
            font-size: 1.4rem;
            position: relative;
            @include bp-down(md) {
                padding: 1.5rem 1rem 1.5rem 4rem;
            }
            @include ie {
                padding: 1.4rem 1rem 1rem 4rem;
            }
            &:before {
                content: "";
                position: absolute;
                top: 12px;
                left: 15px;
                width: 15px;
                height: 15px;
                background-image: url(../images/common/arrow-bg-green.svg);
                @include bp-down(md) {
                    top: 17px;
                }
                @include ie {
                    top: 15px;
                }
            }
        }
    }
    &-tertiary {
        &__set {
            display: flex;
            flex-wrap: wrap;
            margin-top: 1.5rem;
            position: relative;
            border-top: dashed 1px $border-default;
            border-bottom: dashed 1px $border-default;
            &__item {
                width: 50%;
                margin-bottom: -1px;
                border-bottom: dashed 1px $border-default;
                &:nth-child(odd) {
                    border-right: dashed 1px $border-default;
                }
            }
        }
        &__link {
            padding: 1rem 0;
            display: block;
            text-align: center;
            font-size: 1.3rem;
            @include ie {
                padding: 1.3rem 0 0.6rem 0;
            }
            @include bp-down(md) {
                font-size: 1.1rem;
                padding: 1.5rem 0;
            }
        }
    }
}

/* ---------------------------------------------
*   Wrapper
--------------------------------------------- */
.wrapper {
    width: calc(100% - 300px);
    min-width: 860px;
    margin-left: auto;
    @include bp-down(md) {
        width: 100%;
        min-width: 100%;
    }
}
.padding-wrapper {
    padding: 0 20px;
    @include bp-down(md) {
        padding: 0;
    }
}

/* ---------------------------------------------
*   footer
--------------------------------------------- */
.footer {
    background-color: $bg-light;
    width: calc(100% - 300px);
    margin-left: auto;
    @include bp-down(md) {
        width: 100%;
        border-top: solid 2px $border-primary;
    }
    &--top {
        max-width: 1000px;
        margin: 0 auto;
        @include bp-down(md) {
            width: 90%;
        }
    }
    &--comp {
        border-top: solid 2px $border-primary;
        @include bp-down(md) {
            border-top: none;
        }
        @include lay-flex;
        justify-content: flex-start;
        align-items: center;
        @include lay-padding-mid();
        &__logo {
            a {
                @include hover-opacity;
            }
        }
        &__info {
            margin-left: 5rem;
            margin-top: 1rem;
            @include bp-down(md) {
                margin-left: 0;
            }
            line-height: 1;
            &__name {
                font-weight: bold;
                @include bp-down(md) {
                    text-align: center;
                    margin-top: 2rem;
                }
            }
            &__address {
                font-size: 1.3rem;
                margin-top: 0.5rem;
                @include bp-down(md) {
                    margin-top: 1rem;
                }
            }
            &__tel {
                font-size: 1.2rem;
                margin-top: 0.5rem;
                @include bp-down(md) {
                    margin-top: 1rem;
                }
            }
        }
        &__sns-btn {
            margin-left: auto;
            display: flex;
            @include bp-down(md) {
                margin: 2rem auto 0 auto;
            }
            a {
                width: 40px;
                display: block;
                & + a {
                    margin-left: 1rem;
                }
                img {
                    width: 100%;
                }
                @include hover-opacity;
            }
        }
    }
    &-guard {
        @include lay-padding-mid();
        border-top: dashed 1px $border-default;
        &__set {
            &__item {
                font-size: 1.1rem;
                line-height: 1.6;
                &:before {
                    top: 5px;
                    left: 0;
                }
            }
        }
        .approval-number {
            font-size: 1.1rem;
            margin-top: 1rem;
        }
    }
    &-nav {
        background-color: #f6f6f6;
        &__set {
            @include lay-content-inner();
            @include zero-auto;
            @include lay-padding-sm();
            @include lay-flex;
            justify-content: center;
            align-items: center;
            &__item {
                &:not(:first-of-type)::before {
                    margin: 0 0.8em;
                    content: "-";
                }
            }
        }
        &__link {
            font-size: 1.3rem;
        }
    }
    &--bottom {
        background-color: $bg-primary;
        @include lay-padding-sm();
        @include bp-down(md) {
            padding-bottom: 10rem;
        }
        color: #fff;
        text-align: center;
        a {
            color: #fff;
        }
    }
}
.footer-bottom {
    padding: 24px 0;

    box-sizing: border-box;

    @include bp-down(md) {
        padding: pxtovw(20, "sp") pxtovw(15, "sp");
    }

    &__container {
        margin: 0 auto;
    }
}

/*  footer-sub-nav
--------------------------------------------- */
.footer-sub-nav {
    &__list {
        display: flex;
        align-items: center;
    }

    &__list-item {
        margin-left: 20px;

        @include bp-down(md) {
            margin-left: pxtovw(15, "sp");
        }

        &:first-of-type {
            margin-left: 0;
        }
    }

    &__link {
        font-size: $font-size-small;
        font-weight: 500;
        color: $txt-light;
        @include hover-opacity();

        @include bp-down(md) {
            font-size: pxtorem(12, "sp");
        }
    }
}

/*  footer-copyright
--------------------------------------------- */
.footer-copyright {
    @include bp-down(md) {
        margin-top: pxtovw(10, "sp");
    }

    &__text {
        font-size: $font-size-small;
        color: $txt-light;

        @include bp-down(md) {
            font-size: pxtorem(12, "sp");
        }
    }
}

/* ---------------------------------------------
*   mainvisual
--------------------------------------------- */
.mainvisual {
    // height: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 110px 0;
    @include bp-down(md) {
        padding: 40px 0;
    }
    &--advantage {
        background-image: url(../images/advantage/advantage-mv.jpg);
    }
    &--insurance {
        background-image: url(../images/insurance/insurance-mv.jpg);
        padding-bottom: 22rem;
    }
    &--insurance-direct {
        background-image: url(../images/insurance/insurance-direct-mv.png);
    }
    &--insurance-point01 {
        background-image: url(../images/insurance/insurance-point01-mv.png);
    }
    &--insurance-point02 {
        background-image: url(../images/insurance/insurance-point02-mv.png);
    }
    &--insurance-point03 {
        background-image: url(../images/insurance/insurance-point03-mv.png);
    }
    &--insurance-point04 {
        background-image: url(../images/insurance/insurance-point04-mv.png);
    }
    &--insurance-point05 {
        background-image: url(../images/insurance/insurance-point05-mv.png);
    }
    &--housingloan {
        background-image: url(../images/housingloan/housing-mv.png);
        padding-bottom: 22rem;
    }
    &--shop {
        background-image: url(../images/shop/shop-mv.png);
    }
    &--muikamachi,
    &--tokamachi,
    &--nagaoka,
    &--gunmachuo,
    &--gunmaseimo,
    &--gunmaunion {
        background-image: url(../images/shop/shop-child-mv.png);
    }
    &--procedure {
        background-image: url(../images/procedure/procedure-mv.png);
    }
    &--faq {
        background-image: url(../images/faq/faq-mv.png);
    }
    &--accident {
        background-image: url(../images/accident/accident-mv.png);
    }
    &--contact {
        background-image: url(../images/contact/contact-mv.png);
    }
    &--movies {
        background-image: url(../images/movies/bg-bk.png);
    }
    &--company {
        background-image: url(../images/company/company-mv.png);
    }
    &--recruit {
        background-image: url(../images/recruit/recruit-mv.png);
    }
    &--news {
        background-image: url(../images/news/news-mv.png);
    }
    &--sitemap {
        background-image: url(../images/sitemap/sitemap-mv.png);
    }
    &--fiduciaryduty {
        background-image: url(../images/fiduciaryduty/fiduciaryduty-mv.png);
    }
    &--invitation {
        background-image: url(../images/invitation/invitation-mv.png);
    }
    &--privacy {
        background-image: url(../images/privacy/privacy-mv.png);
    }

    @include bp-down(md) {
        // height: pxtovw(200, "sp");
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include bp-down(md) {
            width: 95%;
            margin: 0 auto;
        }
    }

    &__ttl {
        display: inline-block;
        font-weight: bold;
        color: $txt-light;
        letter-spacing: 0.03em;
        text-align: center;
        @include childHead();
        padding-bottom: 2rem;
        border-bottom: 2px solid #fff;
        @include bp-down(md) {
            font-size: 2rem;
        }
        &--insurance,
        &--housingloan {
            font-size: 2.5rem;
            border: none;
            padding-bottom: 0;
            @include bp-down(md) {
                font-size: 2rem;
                padding-bottom: 8rem;
            }
        }
        @include bp-down(md) {
            // padding-bottom: 1rem;
        }
    }

    &__txt {
        margin-top: 3rem;
        font-size: $font-size-default;
        font-weight: bold;
        text-align: center;
        color: #fff;

        @include bp-down(md) {
            margin-top: 1rem;
        }
    }
}

.inner-bold {
    font-weight: bold;
}
.catch-bold {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
}
/* ---------------------------------------------
*   contents
--------------------------------------------- */
.contents {
    position: relative;

    @include bp-down(md) {
        overflow: hidden;
    }

    &--top {
        background: none;
    }
}
.lay-content {
    &__inner {
        @include lay-content-inner();
        @include zero-auto;
    }
}
.sec-ttl-lev2 {
    & + * {
        margin-top: $margin-default;
    }
}
.sec-ttl-lev3 {
    margin-top: $margin-default;
    & + * {
        margin-top: $margin-default;
    }
}
.sec-ttl-lev4 {
    margin-top: $margin-default;
    & + * {
        margin-top: $margin-default;
    }
}
.sec-ttl-lev5 {
    margin-top: $margin-default;
    & + * {
        margin-top: $margin-default;
    }
}

.tab-menu__btn {
    width: 51rem;
    @include zero-auto(4rem, auto);
    display: flex;
    justify-content: center;
    @include bp-down(md) {
        width: 90%;
        margin-top: 2rem;
    }
    &__item {
        text-align: center;
        padding: 2rem 0;
        border: 1px solid $border-default;
        width: 49%;
        font-weight: bold;
        border-radius: $radius-default;
        position: relative;
        z-index: 2;
        @include bp-down(md) {
            padding: 1rem 0;
        }
        &.active {
            border: 2px solid $border-primary;
            transition: 0.2s;
            &:before {
                content: "";
                width: 15px;
                height: 15px;
                background-color: #fff;
                position: absolute;
                bottom: -9px;
                left: 0;
                right: 0;
                margin: auto;
                border-left: 2px solid $border-primary;
                border-bottom: 2px solid $border-primary;
                transform: rotate(-45deg);
                z-index: 1;
            }
        }
        &:not(.active) {
            cursor: pointer;
            background-color: $bg-primary;
            color: #fff;
            &:hover {
                opacity: 0.7;
            }
        }
        & + li {
            margin-left: auto;
        }
    }
}
.tab-menu__contents {
    @include lay-content-inner();
    @include zero-auto(4rem, 0);
    &__item {
        // padding: 2rem;
        display: none;
        &.show {
            display: block;
            transition: 0.2s;
        }
    }
    .tab-catch {
        margin-bottom: 3rem;
    }
}

.lay-card-item-col2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &__item {
        width: 49%;
        background-color: $bg-muted;
        padding: 4rem 0;
        margin-top: 1rem;
        border-radius: $radius-default;
        box-shadow: $shadow-default;
        @include bp-down(md) {
            width: 48%;
        }
        p {
            text-align: center;
        }
    }
}
.lay-card-set-col2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include bp-down(md) {
        justify-content: space-between;
    }
    &__item {
        width: calc((#{$width-base} / 2) - 1rem);
        background-color: $bg-muted;
        padding: 4rem 0;
        margin-top: 1rem;
        @include bp-down(md) {
            width: 48%;
        }
        & + li {
            margin-left: 2rem;
            @include bp-down(md) {
                margin-left: 0;
                margin-top: 1rem;
            }
        }
        &.space-none {
            margin-left: 0;
        }
        p {
            text-align: center;
        }
    }
}
$lay-col3-width: 32%;
.lay-card-item-col3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include bp-down(md) {
        flex-direction: column;
    }
    &__item {
        width: $lay-col3-width;
        background-color: $bg-muted;
        padding: 4rem 0;
        margin-top: 1rem;
        border-radius: $radius-default;
        box-shadow: $shadow-default;
        @include bp-down(md) {
            width: 100%;
        }
        p {
            text-align: center;
        }
    }
    &:after {
        content: "";
        width: $lay-col3-width;
    }
}
.lay-card-set-col3 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include bp-down(md) {
        flex-direction: column;
    }
    &__item {
        width: calc((#{$width-base} / 3) - 1rem);
        background-color: $bg-muted;
        padding: 4rem 0;
        margin-top: 1rem;
        @include bp-down(md) {
            width: 100%;
        }
        & + li {
            margin-left: 1.5rem;
            @include bp-down(md) {
                margin-left: 0;
                margin-top: 1rem;
            }
        }
        &.space-none {
            margin-left: 0;
        }
        p {
            text-align: center;
        }
    }
}
$lay-col4-width: 24%;
.lay-card-item-col4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include bp-down(md) {
        flex-direction: column;
    }
    &:before {
        content: "";
        width: $lay-col4-width;
        visibility: hidden;
        order: 1;
    }
    &:after {
        content: "";
        width: $lay-col4-width;
        visibility: hidden;
    }
    &__item {
        width: $lay-col4-width;
        background-color: $bg-muted;
        padding: 4rem 0;
        margin-top: 1rem;
        border-radius: $radius-default;
        box-shadow: $shadow-default;
        @include bp-down(md) {
            width: 100%;
        }
        p {
            text-align: center;
        }
    }
}
$lay-col5-width: 19%;
.lay-card-item-col5 {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-flow: row wrap;
    @include bp-down(md) {
        flex-direction: column;
    }
    &__item {
        width: $lay-col5-width;
        background-color: $bg-muted;
        padding: 4rem 0;
        margin-top: 1rem;
        @include bp-down(md) {
            width: 100%;
        }
        & + li {
            margin-left: 1.25%;
            @include bp-down(md) {
                margin-left: 0;
                margin-top: 1rem;
            }
        }
        &.space-none {
            margin-left: 0;
        }
        p {
            text-align: center;
        }
    }
}
.scroll-cap {
    margin-bottom: 1rem;
    @include bp-up(md) {
        display: none;
    }
}
.scroller {
    margin-top: 2rem;
    @include bp-down(md) {
        overflow-x: scroll;
        margin-top: 0;
    }
}
.lay-table {
    width: $width-base;
    margin-bottom: 2rem;
    &__head {
        width: 30%;
        background-color: $bg-muted;
        padding: 1rem;
        border: 1px solid $border-default;
        @include bp-down(md) {
            width: 20%;
        }
    }
    &__content {
        width: 70%;
        padding: 1rem;
        border: 1px solid $border-default;
        background-color: #fff;
        @include bp-down(md) {
            width: 75%;
        }
    }
}
.lay-table-col {
    width: $width-base;
    margin-bottom: 2rem;
    border-collapse: collapse;
    @include bp-down(md) {
        width: 100%;
    }
    &__head {
        width: 30%;
        background-color: $bg-muted;
        padding: 1rem;
        border: 1px solid $border-default;
        @include bp-down(md) {
            width: 100%;
            display: inline-block;
            border-width: 1px 1px 0 1px;
        }
    }
    &__content {
        width: 70%;
        padding: 1rem;
        border: 1px solid $border-default;
        background-color: #fff;
        @include bp-down(md) {
            width: 100%;
            display: inline-block;
            border-width: 1px 1px 0 1px;
        }
    }
    tr {
        &:last-child {
            td {
                @include bp-down(md) {
                    border-width: 1px 1px 1px 1px;
                }
            }
        }
    }
}

.lay-list-desc-set {
    &__item {
        position: relative;
        padding-left: 1.6rem;
        &:before {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: $border-default;
        }
    }
    &--green {
        &__item {
            font-size: 1.3rem;
            &.font-bold {
                font-weight: bold;
            }
            &:before {
                top: 4px;
                left: 0;
                background-color: $bg-primary-dark;
                width: 8px;
                height: 8px;
            }
        }
    }
}
.lay-list-desc-set-green {
    &__item {
        position: relative;
        padding-left: 1.6rem;
        font-size: 1.5rem;
        font-weight: normal;
        &:before {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: $bg-primary;
            top: 5px;
            left: 0;
        }
    }
    &--lay-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__item {
            width: 50%;
            @include bp-down(md) {
                & + li {
                    margin-top: 0.5rem;
                }
                width: 100%;
                font-size: 1.3rem;
            }
        }
    }
    &--invitation {
        &__item {
            padding-left: 2.2rem;
            &:before {
                width: 15px;
                height: 15px;
                border-radius: 8px;
            }
        }
    }
}
.arrow-green {
    line-height: 1;
    text-decoration: underline;
    font-weight: normal;
    display: flex;
    margin-top: 1rem;
    > span {
        display: block;
        align-items: center;
        margin-right: 0.5rem;
    }
}
.page-ttl {
    &--catch {
        font-size: 1.7rem;
        color: $txt-primary-dark;
        font-weight: bold;
        // text-align: center;
        &--home {
            text-align: center;
        }
        &--center {
            text-align: center;
        }
        &--sp-center {
            @include bp-down(md) {
                text-align: center;
            }
        }
        @include bp-down(md) {
            font-size: 1.7rem;
        }
    }
    &--ttl {
        font-size: 3rem;
        font-weight: bold;
        margin-top: 0.5rem;
        &--child {
            text-align: left;
            @include bp-down(md) {
                text-align: center;
            }
        }
        &--center {
            text-align: center;
        }
        // &--home {
        //     text-align: center;
        // }
        @include bp-down(md) {
            font-size: 2rem;
        }
    }
    &--txt {
        width: 700px;
        @include zero-auto(4rem, 0);
        line-height: 2;
    }
    &--child-catch {
        margin-top: 4rem;
    }
}
.page-ttl-catch {
    max-width: 800px;
    @include zero-auto(4rem, 0);
    line-height: 2;
    @include bp-down(md) {
        margin-top: 2rem;
    }
    &--center {
        text-align: center;
    }
}
.mod-list {
    width: 50%;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
    @include bp-down(md) {
        width: 100%;
    }
    &__item {
        &:nth-child(even) {
            background-color: #f6f6f6;
            .mod-list__item__link {
                background-color: #f6f6f6;
            }
        }
        &__link {
            background-color: #fff;
            font-size: 1.7rem;
            display: flex;
            position: relative;
            padding: 2rem;
            border-left: 3px solid $border-primary;
            @include bp-down(md) {
                font-size: 1.4rem;
                padding: 1.7rem;
            }
            span {
                display: block;
            }
            // &:nth-child(even) {
            //     background-color: $bg-muted-light;
            // }
            &:after {
                content: "";
                width: 7px;
                height: 10px;
                background-image: url(../images/common/arrow-green.svg);
                background-repeat: no-repeat;
                position: absolute;
                top: 40%;
                right: 3%;
            }
        }
        &__numb {
            color: $txt-primary-dark;
            font-size: 25px;
            @include robocon;
            line-height: 1;
            margin-right: 2rem;
            @include bp-down(md) {
                margin-right: 1rem;
            }
        }
        &__txt {
            font-weight: bold;
        }
    }
}
.contents--main {
    &__inner {
        @include lay-content-inner();
        margin: 0 auto;
    }
}
.left-content-box {
    padding: 8rem 0;
    position: relative;
    overflow: hidden;
    background-position: right;
    background-repeat: no-repeat;
    &__wrapper {
        @include lay-content-inner;
        @include zero-auto;
    }
    &__set {
        position: relative;
        z-index: 2;
        margin-top: 4rem;
    }
    &__ttl {
        text-shadow: 2px 2px 0 #fff;
        position: relative;
        z-index: 2;
    }
}
.risk-hedge {
    padding: 8rem 0;
    position: relative;
    overflow: hidden;
    background-position: right;
    background-repeat: no-repeat;
    @include bp-down(md) {
        padding: 4rem 0 20rem 0;
        background-size: 100%;
        background-position: bottom;
    }
    &--home {
        // background-image: url(../images/home/risk-hedge-bg.png);
        padding: 8rem 0;
        background-size: contain;
        background-position: 140%;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            width: calc(50% + 8rem);
            height: 100%;
            background-image: url(../images/home/risk-hedge-bg.png);
            background-size: cover;
            right: 0;
            top: 0;
            @include bp-down(md) {
                width: 100%;
                left: 0;
                top: 330px;
                bottom: 0;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
    &--insurance {
        background-image: url(../images/insurance/insurance-risk-hedge.png);
        background-color: #fff;
        padding: 7rem 0 10rem 0 !important;
        @include bp-down(md) {
            padding: 5rem 0 25rem 0 !important;
        }
    }
    &--wrapper {
        @include lay-content-inner;
        @include zero-auto;
    }
    &__set {
        position: relative;
        z-index: 2;
        margin-top: 4rem;
        @include bp-down(md) {
            margin-top: 3rem;
        }
    }
    &__ttl {
        text-shadow: 2px 2px 0 #fff;
        position: relative;
        z-index: 2;
        text-align: left;
    }
    &__catch {
        text-align: left;
        font-weight: bold;
        color: $txt-primary-dark;
    }
}

.page-ttl-level-sec {
    @include childConte();
    text-align: center;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.page-top {
    background-image: url(../images/common/bg-green.png);
    background-size: cover;
    width: 40px;
    height: 40px;
    position: fixed;
    right: 15px;
    bottom: 80px;
    border-radius: 5px;
    z-index: 3;
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.15);
    @include bp-down(md) {
        bottom: 10rem;
    }
    a {
        display: block;
        padding: 6px;
        @include ie {
            padding: 10px;
        }
        &:hover {
            opacity: 0.6;
        }
        img {
            transform: rotate(-90deg);
            width: 11px;
            height: auto;
            display: block;
            margin: 5px auto 0 auto;
            @include ie {
                width: 7px;
            }
        }
    }
}
.contact {
    .page-top {
        @include bp-down(md) {
            bottom: 16rem;
        }
    }
}
.draw-in {
    animation: fadeIn 0.7s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
}
.ofi-img {
    font-family: "object-fit: cover; object-position: bottom;";
}
