@use "../mixins" as *;
@use "../variables" as *;

.contents--main--faq {
    padding: 7rem 0 13rem 0;
    background-color: $bg-primary-lighter;
    @include bp-down(md) {
        padding: 3rem 0 4rem 0;
    }
}

.faq {
    &__contents {
        @include lay-content-inner;
        @include zero-auto();
    }
    &__item {
        & + li {
            margin-top: 1rem;
        }
        &.open {
            .faq__question {
                &:after {
                    transform: rotate(45deg);
                    transition: 0.3s;
                }
            }
        }
    }
    &__question {
        background-color: #fff;
        padding: 2rem;
        position: relative;
        border-left: 2px solid $border-primary;
        font-weight: bold;
        display: flex;
        align-items: center;
        cursor: pointer;
        @include bp-down(md) {
            padding: 1.5rem;
        }
        &:after {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            background-color: #fff;
            border-left: 3px solid $border-primary;
            border-top: 3px solid $border-primary;
            top: 30px;
            right: 25px;
            transform: rotate(-135deg);
            transition: 0.3s;
            @include bp-down(md) {
                top: 20px;
                height: 8px;
                width: 6px;
                height: 6px;
                border-left: 2px solid $border-primary;
                border-top: 2px solid $border-primary;
                right: 10px;
            }
        }
    }
    &__question-txt {
        font-size: 1.4rem;
        line-height: 1.4;
        @include bp-down(md) {
            padding: 0 2rem;
        }
    }
    &__question-icon {
        background-color: $bg-primary;
        color: #fff;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        text-align: center;
        padding-top: 4px;
        @include robocon;
        margin-right: 2rem;
        @include bp-down(md) {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin-right: 1rem;
            position: absolute;
            left: 8px;
            padding-top: 2px;
            font-size: 1.1rem;
        }
    }
    &__answer {
        background-color: $bg-accent1-lightest;
        padding: 3rem;
        border-left: 2px solid $border-primary;
        display: none;
        @include bp-down(md) {
            padding: 2rem;
            font-size: 1.3rem;
        }
    }
}
