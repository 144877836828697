@use "../mixins" as *;
@use "../variables" as *;

.company--main {
    padding: 3rem 0 0 0;
}
.company--contents {
    border-top: 2px solid $border-primary;
    background-color: $bg-primary-lighter;
    padding: 7rem 0 13rem 0;
    @include bp-down(md) {
        padding: 0 0 4rem 0;
    }
    &__inner {
        @include lay-content-inner;
        @include zero-auto;
    }
    &--detail {
        padding-bottom: 23rem;
    }
}
.company--greeting {
    padding: 3rem 0 7rem 0;

    &__ttl {
        text-align: center;
        font-size: 3rem;
        @include bp-down(md) {
            font-size: 2.4rem;
        }
    }
    &__thumb01 {
        margin-top: 3.5rem;
        @include bp-down(md) {
            margin-top: 2rem;
            img {
                object-fit: cover;
                height: 200px;
            }
        }
    }
    &__txt {
        max-width: 800px;
        line-height: 2;
        border-bottom: 2px solid #ccc;
        padding-bottom: 7rem;
        @include zero-auto(3rem, 0);
        @include bp-down(md) {
            margin-top: 2rem;
        }
        * {
            margin-top: 2rem;
        }
        &__sign {
            text-align: right;
            @include bp-down(md) {
                width: 50%;
                margin-top: 0;
                margin-left: auto;
            }
        }
        .lay-list-desc-set {
            .lay-list-desc-set__item {
                margin-top: 0;
                &:before {
                    top: 8px;
                    background-color: $bg-primary;
                }
            }
        }
    }
}
.company--greeting02 {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    @include zero-auto;
    @include bp-down(md) {
        flex-direction: column-reverse;
    }
    &__txt {
        width: 500px;
        @include bp-down(md) {
            width: 100%;
            margin-top: 2rem;
        }
        p {
            margin-top: 2rem;
            line-height: 2;
            &:first-child {
                margin-top: 0;
            }
        }
    }
    &__thumb {
        width: 250px;
        &__sign {
            margin-top: 3rem;
            text-align: right;
            @include bp-down(md) {
                width: 50%;
                margin-left: auto;
            }
        }
        @include bp-down(md) {
            width: 100%;
            .presi-thimb02 {
                width: 100%;
                height: 200px;
                object-fit: cover;
            }
            img {

            }
        }
    }
    .presi-thimb02-sp {
        width: 100%;
        max-width: 100%;
    }
}

.company--philosophy {
    &__ttl {
        text-align: center;
        font-size: 3rem;
        @include bp-down(md) {
            font-size: 2.4rem;
            margin-top: 2rem;
        }
    }
    &__manifest {
        background-color: #fff;
        border: 2px solid $border-primary;
        padding: 4rem 0;
        margin-top: 3.5rem;
        @include bp-down(md) {
            margin-top: 2rem;
            padding: 0 2rem;
        }
        &__list {
            max-width: 600px;
            @include zero-auto;
            &__item {
                padding: 3rem 0;
                @include bp-down(md) {
                    padding: 2rem 0;
                }
                .font-accent {
                    color: $txt-primary;
                }
                & + li {
                    border-top: 1px dashed #ccc;
                }
                &__ttl {
                    font-size: 5rem;
                    text-align: center;
                    line-height: 1;
                    @include bp-down(md) {
                        font-size: 3rem;
                    }
                }
                &__sub {
                    font-weight: bold;
                    font-size: 1.7rem;
                    text-align: center;
                    margin-top: 2rem;
                }
            }
        }
    }
}
.comp-thumbs {
    @include lay-content-inner;
    @include zero-auto(-14rem, 8rem);
    @include bp-down(md) {
        margin-top: 0;
        margin-bottom: 4rem;
    }
    &__list {
        display: flex;
        justify-content: space-between;
    }
}
.company--overview {
    @include lay-content-inner;
    @include zero-auto();
    padding-bottom: 8rem;
    @include bp-down(md) {
        padding-bottom: 4rem;
    }
    &__ttl {
        text-align: center;
        font-size: 3rem;
        @include bp-down(md) {
            font-size: 2.4rem;
        }
    }
    &__table {
        margin-top: 3.5rem;
        @include bp-down(md) {
            margin-top: 2rem;
        }
    }
}
.comp-alliance {
    padding: 8rem 0 15rem 0;
    background-color: $bg-primary-lighter;
    @include bp-down(md) {
        padding: 3rem 0 4rem 0;
    }
    &__inner {
        @include lay-content-inner;
        @include zero-auto();
    }
	&__ttl {
		font-size: 3rem;
		text-align: center;
        @include bp-down(md) {
            font-size: 2.4rem;
        }
	}
}
.table-lay-col2 {
    width: 100%;
    @include ie {
        table-layout: fixed;
    }
    tr {
        &:last-child {
            td {
                border-bottom: 1px solid #ccc;
            }
        }
        th {
            width: 240px;
            background-color: #f6f6f6;
            padding: 2.5rem 3rem;
            border: 1px solid #ccc;
            @include bp-down(md) {
                width: 100%;
                display: block;
                border-bottom: none;
                padding: 1.5rem;
            }
        }
        td {
            width: 720px;
            background-color: #fff;
            padding: 2.5rem 3rem;
            border: 1px solid #ccc;
            @include bp-down(md) {
                width: 100%;
                display: block;
                border-bottom: none;
                padding: 1.5rem;
            }
        }
    }
}
