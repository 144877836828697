@mixin widthSet() {
    max-width: 960px;
    @include bp-down(md) {
        width: 90%;
    }
}
@mixin sp_width_center($mt-def:0, $mb-def:0) {
    width: 90%;
    margin: $mt-def auto $mb-def auto;
}
