@use "../mixins" as *;
@use "../variables" as *;

/* ---------------------------------------------
*   js-tel-disabled
--------------------------------------------- */
.js-tel-disabled {
	cursor: default;
	outline: none;

	&:hover {
		text-decoration: none;
		opacity: 1 !important;
	}
}

/* ---------------------------------------------
*   remodal-close
--------------------------------------------- */
.remodal-close {
	$host: &;
	top: 0 !important;
	left: auto !important;
	right: 0;
	z-index: 10;
	width: 50px !important;
	height: 50px !important;
	@include hover-opacity();

	@include bp-down(md) {
		top: pxtovw(-1, 'sp') !important;
	}

	&:before {
		content: none !important;
	}
}

/* ---------------------------------------------
*   remodal
--------------------------------------------- */
.remodal {
	$host: &;
	margin: 0 !important;
	padding: 0 !important;
	overflow: hidden;
}
