@use "../variables" as *;
@use "mediaquery" as *;

@mixin inner_base {
    @include bp-down(md) {
        padding-right: $contents-side-padding-sp;
        padding-left: $contents-side-padding-sp;
        min-width: $minwidth-sp;
    }
    @include bp-up(md) {
        margin-right: auto;
        margin-left: auto;
        padding-right: $contents-side-padding-pc;
        padding-left: $contents-side-padding-pc;
        width: $minwidth-pc;
    }
}
@mixin lay-content-inner($pc-width: $width-base, $sp-width: $width-base-sp) {
    max-width: $pc-width;
    @include bp-down(md) {
        width: $sp-width;
        padding: 0;
    }
}
