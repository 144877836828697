@use "../mixins" as *;

// ---------------------------------------------
//   PC/SP レスポンシブの出しわけスタイル
//----------------------------------------------
@include bp-up(md) {
    .pc-none {
        display: none !important;
    }
}
@include bp-down(md) {
    .sp-none {
        display: none !important;
    }
}
