@use "../mixins" as *;
@use "../variables" as *;


.mainvisual {

	&--staff {
        background-image: url(/assets/image/staff/img_mv_staff.png);
    }
}
/* ---------------------------------------------
*   staff
--------------------------------------------- */
.staff {
	$host: &;

	&__container {
		max-width: 1000px;
		margin: 0 auto;
		padding: 90px 0 70px;
		box-sizing: border-box;

		@include bp-down(md) {
			padding: pxtovw(50, 'sp') pxtovw(15, 'sp');
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;

		@include bp-down(md) {
			display: block;
		}
	}

	&__item {
		width: 312px;
		margin-top: 40px;
		margin-left: 31px;
		border-radius: 15px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);

		@include bp-down(md) {
			width: 100%;
			margin-top: pxtovw(30, 'sp');
			margin-left: 0;
			border-radius: pxtovw(15, 'sp');
		}

		&:nth-of-type(3n+1) {
			margin-left: 0;
		}
	}

	&__upper {
		position: relative;
	}

	&__img {
		width: 100%;

		img {
			width: 100%;
		}
	}

	&__position {
		display: inline-block;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 4px 25px;
		// font-size: pxtorem(14, 'pc');
		font-weight: bold;
		color: $txt-light;
		background-color: $bg-primary-dark;

		@include bp-down(md) {
			padding: pxtovw(4, 'sp') pxtovw(25, 'sp');
			font-size: pxtorem(14, 'sp');
		}
	}

	&__lower {
		padding: 25px 30px 30px;
		text-align: center;
		background-color: $bg-primary-lighter;
		border-radius: 0 0 10px 10px;

		@include bp-down(md) {
			padding: pxtovw(20, 'sp') pxtovw(30, 'sp') pxtovw(25, 'sp');
			border-radius: 0 0 pxtovw(10, 'sp') pxtovw(10, 'sp');
		}

		.mod-btn {
			margin-top: 20px;

			@include bp-down(md) {
				margin-top: pxtovw(20, 'sp');
			}
		}
	}

	&__name-ja {
		// font-size: pxtorem(20, 'pc');
		font-weight: bold;

		@include bp-down(md) {
			font-size: pxtorem(20, 'sp');
		}
	}

	&__name-en {
		// font-size: pxtorem(14, 'pc');
		font-weight: bold;
		color: $txt-primary;

		@include bp-down(md) {
			font-size: pxtorem(14, 'sp');
		}
	}
}

/*  staff-modal
--------------------------------------------- */
.staff-modal {
	$host: &;
	position: relative;
	width: 700px;
	text-align: left;

	@include bp-down(md) {
		width: 100%;
	}

	&__upper {
		position: relative;
	}

	&__name {
		position: absolute;
		top: 57%;
		left: 61%;
		transform: translateY(-50%);
		text-align: center;

		@include bp-down(md) {
			position: static;
			margin-top: pxtovw(10, 'sp');
			transform: none;
		}
	}

	&__position {
		display: inline-block;
		padding: 4px 25px;
		// font-size: pxtorem(14, 'pc');
		font-weight: bold;
		color: $txt-light;
		background-color: $bg-primary-dark;

		@include bp-down(md) {
			padding: pxtovw(4, 'sp') pxtovw(25, 'sp');
			font-size: pxtorem(14, 'sp');
		}
	}

	&__name-ja {
		margin-top: 15px;
		// font-size: pxtorem(30, 'pc');
		font-weight: bold;

		@include bp-down(md) {
			margin-top: pxtovw(10, 'sp');
			font-size: pxtorem(26, 'sp');
		}
	}

	&__name-en {
		// font-size: pxtorem(14, 'pc');
		font-weight: bold;
		color: $txt-primary;

		@include bp-down(md) {
			font-size: pxtorem(14, 'sp');
		}
	}

	&__lower {
		padding: 40px 40px 30px;
		background-color: $bg-primary-lighter;

		@include bp-down(md) {
			padding: pxtovw(10, 'sp') pxtovw(15, 'sp') pxtovw(15, 'sp');
		}
	}
}

/*  staff-profile
--------------------------------------------- */
.staff-profile {
	$host: &;

	&__head {
		padding: 5px 10px;
		// font-size: pxtorem(16, 'pc');
		font-weight: bold;
		color: $txt-light;
		background-color: $bg-primary;

		@include bp-down(md) {
			padding: pxtovw(3, 'sp') pxtovw(10, 'sp');
			font-size: pxtorem(16, 'sp');
		}
	}

	&__list {
		display: flex;
		align-items: center;
		margin-top: 15px;

		@include bp-down(md) {
			display: block;
			margin-top: pxtovw(10, 'sp');
		}
	}

	&__term {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 110px;
		height: 33px;
		// font-size: pxtorem(14, 'pc');
		font-weight: bold;
		color: $txt-primary;
		background-color: $bg-primary;

		@include bp-down(md) {
			width: 100%;
			height: pxtovw(33, 'sp');
			font-size: pxtorem(14, 'sp');
		}
	}

	&__desc {
		margin-left: 13px;
		// font-size: pxtorem(14, 'pc');
		font-weight: bold;
		box-sizing: border-box;

		@include bp-down(md) {
			width: 100%;
			margin-top: pxtovw(5, 'sp');
			margin-left: 0;
			padding: 0 pxtovw(10, 'sp');
			font-size: pxtorem(14, 'sp');
		}
	}
}

/*  staff-cert
--------------------------------------------- */
.staff-cert {
	$host: &;
	margin-top: 30px;

	@include bp-down(md) {
		margin-top: pxtovw(20, 'sp');
	}

	&__head {
		padding: 5px 10px;
		// font-size: pxtorem(16, 'pc');
		font-weight: bold;
		color: $txt-light;
		background-color: $bg-primary;

		@include bp-down(md) {
			padding: pxtovw(3, 'sp') pxtovw(10, 'sp');
			font-size: pxtorem(16, 'sp');
		}
	}

	&__list {
		margin-top: 10px;

		@include bp-down(md) {
			margin-top: pxtovw(10, 'sp');
		}
	}

	&__item {
		position: relative;
		margin-top: 7px;
		padding-left: 22px;
		// font-size: pxtorem(14, 'pc');
		font-weight: bold;
		line-height: 1.7;

		@include bp-down(md) {
			margin-top: pxtovw(5, 'sp');
			padding-left: pxtovw(22, 'sp');
			font-size: pxtorem(14, 'sp');
			line-height: 1.5;
		}

		&:before {
			content: "";
			position: absolute;
			top: 6px;
			left: 5px;
			width: 9px;
			height: 9px;
			background-color: $bg-primary;
			border-radius: 50%;

			@include bp-down(md) {
				top: pxtovw(6, 'sp');
				left: pxtovw(5, 'sp');
				width: pxtovw(9, 'sp');
				height: pxtovw(9, 'sp');
			}
		}
	}
}

/*  staff-message
--------------------------------------------- */
.staff-message {
	$host: &;
	margin-top: 25px;
	background: url(/assets/image/common/bg_message.png) 0 0 no-repeat;
	background-size: 100% 100%;

	@include bp-down(md) {
		margin-top: pxtovw(20, 'sp');
	}

	&__container {
		padding: 22px 25px 30px;

		@include bp-down(md) {
			padding: pxtovw(15, 'sp') pxtovw(20, 'sp') pxtovw(20, 'sp');
		}
	}

	&__head {
		width: 202px;

		@include bp-down(md) {
			width: pxtovw(200, 'sp');
		}
	}

	&__body {
		margin-top: 12px;

		@include bp-down(md) {
			margin-top: pxtovw(10, 'sp');
		}
	}

	&__txt {
		padding: 2px 0;
		// font-size: pxtorem(14, 'pc');
		background: linear-gradient(transparent 92%, $bg-muted 0%);
		background-size: 100% 2.1em;
		line-height: 2.1;

		@include bp-down(md) {
			padding: pxtovw(2, 'sp') 0;
			font-size: pxtorem(14, 'sp');
		}
	}
}
