@use "../mixins" as *;
@use "../variables" as *;

// Section Blocks
// -----------------------------------------------------------------------------
.sec {
    position: relative;
}
.sec--lv1 {
    position: relative;
    margin-top: 5rem;
    @include bp-up(md) {
        margin-top: 10rem;
    }
}

.sec--lv2 {
    position: relative;
    margin-top: 3rem;
    @include bp-up(md) {
        margin-top: 5rem;
    }
}

.sec--lv3 {
    position: relative;
    margin-top: 2rem;
    @include bp-up(md) {
        margin-top: 3rem;
    }
}

.sec--lv4 {
    position: relative;
    margin-top: 1.5rem;
    @include bp-up(md) {
        margin-top: 2rem;
    }
}

.sec--lv5 {
    position: relative;
    margin-top: 1rem;
}

// Inner Block
.sec-inner {
    @include inner_base;
}

.sec-inner--lv1 {
    @include inner_base;
    padding-top: 5rem;
    padding-bottom: 5rem;
    @include bp-up(md) {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }
}

.sec-inner--lv2 {
    @include inner_base;
    padding-top: 3rem;
    padding-bottom: 3rem;
    @include bp-up(md) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

.sec-inner--lv3 {
    @include inner_base;
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include bp-up(md) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.sec-inner--lv4 {
    @include inner_base;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.sec-inner--lv5 {
    @include inner_base;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

// Etc Blocks
// -----------------------------------------------------------------------------
.sec-inner--slim {
    @include bp-up(md) {
        padding-right: calc(15rem + #{$contents-side-padding-pc});
        padding-left: calc(15rem + #{$contents-side-padding-pc});
    }
}

.block--slim {
    @include bp-up(md) {
        padding-right: 15rem;
        padding-left: 15rem;
    }
}
.display--inlineblock {
    display: inline-block;
}
