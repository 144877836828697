@use "../mixins" as *;
@use "../variables" as *;

/* ---------------------------------------------
*   mod-tab-list
--------------------------------------------- */
.mod-tab-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 478px;
    @include zero-auto(0, -2px);
    @include bp-down(md) {
        width: 90%;
    }

    &__item {
        width: 237px;
        height: 62px;
        font-weight: bold;
        box-sizing: border-box;
        border-radius: 5px 5px 0 0;

        @include bp-down(md) {
            width: 49%;
            height: auto;
        }
    }

    &__txt {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $txt-primary-dark;
        background-color: $bg-primary-lighter;
        border-radius: 5px 5px 0 0;
        border-top: 2px solid $border-primary;
        border-left: 2px solid $border-primary;
        border-right: 2px solid $border-primary;
        @include bp-down(md) {
            padding: 1rem;
        }
    }

    &__link {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: $bg-primary-dark;
        transition-property: color, background-color;
        transition-duration: 0.3s, 0.3s;
        border-top: 2px solid $border-primary-dark;
        border-left: 2px solid $border-primary-dark;
        border-right: 2px solid $border-primary-dark;
        border-radius: 5px 5px 0 0;
        @include bp-down(md) {
            padding: 1rem 0;
        }

        &:hover {
            position: relative;
            color: $txt-light;
            background-color: $bg-primary;
            border-top: 2px solid $border-primary;
            border-left: 2px solid $border-primary;
            border-right: 2px solid $border-primary;
        }
    }
}
.list_free {
	padding-left: 0;
	list-style: none;
    margin-top: 2rem;
	li {
		margin-left: 1.25em;
		text-indent: -1.25em;
        & + *{
            margin-top: 5px;
        }
	}
}
