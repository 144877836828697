@use "../mixins" as *;
@use "../variables" as *;

/* ---------------------------------------------
*   home
--------------------------------------------- */

.content-out {
    padding: $padding-default 0;
    &__inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include bp-down(md) {
            flex-direction: column;
        }
        .list-item {
            width: 24%;
            padding: 1rem;
            margin-bottom: 1rem;
            box-shadow: 2px 3px 3px #e8e8e8;
            border: 1px solid #e9e9e9;
            @include bp-down(md) {
                width: 100%;
                & + li {
                    margin-top: 1rem;
                }
            }
            a {
                &:hover {
                    opacity: 0.8;
                    transition: 0.3s;
                }
                .common_content {
                    color: #333;
                    .common_category {
                        font-size: 12px;
                        background-color: $bg-muted;
                        color: #fff;
                        width: 100px;
                        padding: 5px;
                        text-align: center;
                        margin: 0.5rem 0;
                    }
                }
                .common_title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .common_img {
                margin-bottom: 1rem;
                img {
                    object-fit: cover;
                    height: 140px;
                    width: 100%;
                }
            }
        }
    }
}
.mv {
    @include bp-down(md) {
        background-color: #f7f8ec;
    }
}
.mv__container {
    position: relative;
    height: 100vh;
    max-height: 900px;
    @include bp-down(md) {
        height: 300px;
    }
    &--img-area {
        img {
            width: 100%;
            height: 100vh;
            object-fit: cover;
            max-height: 900px;
            @include bp-down(md) {
                height: 300px;
            }
        }
    }
    &--txt-area {
        position: absolute;
        top: 60%;
        left: 0;
        @include bp-down(md) {
            top: 55%;
        }
        &__catch {
            color: #fff;
            font-size: 4rem;
            font-weight: bold;
            background-image: url(../images/home/home-mv-txt-bg.png);
            background-size: cover;
            background-repeat: no-repeat;
            width: 630px;
            text-align: center;
            @include ie {
                line-height: 1;
                padding: 1.6rem 0 0 0;
            }
            @include bp-down(md) {
                width: 303px;
                font-size: 2rem;
                padding: 0.4rem 0;
                line-height: 1;
                background-size: cover;
            }
            &__inline {
                color: #ffff00;
            }
            & + p {
                margin-top: 0.5rem;
            }
        }
    }
}
.ticker-wrapper {
    padding: 0 20px;
    @include bp-down(md) {
        padding: 0;
    }
    &__inner {
        background-color: #fff;
        overflow: hidden;
        max-width: 960px;
        height: 60px;
        margin: 0 auto;
        z-index: 3;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -6rem;
        @include bp-down(md) {
            height: 120px;
            width: 90%;
            max-width: 90%;
            // flex-wrap: wrap;
            display: block;
            margin-top: -5rem;
        }
    }
    .info-ttl {
        font-size: 1.5rem;
        // padding: 0 4rem;
        text-align: center;
        border-right: 1px dashed #707070;
        width: 140px;
        @include bp-down(md) {
            width: 100%;
            height: 50px;
            text-align: left;
            padding: 1.3rem 1.5rem;
            border-right: none;
            border-bottom: 1px dashed #707070;
        }
    }
    .archive-link {
        background-image: url(../images/common/bg-green.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        margin-left: auto;
        @include bp-down(md) {
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 49px;
        }
        &:hover {
            opacity: 0.7;
        }
    }
}
.topics-ticker {
    position: relative;
    width: 760px;
    z-index: 3;
    height: 60px;
    overflow: hidden;
    // max-width: 760px;
    // margin: 0 auto;
    padding-left: 40px;
    @include bp-down(md) {
        width: 100%;
        padding: 15px;
        height: 70px;
        // margin-top: 3rem;
        // max-width: 96%;
    }
    &__list {
        display: flex;
        align-items: center;
        // padding-left: 14rem;
        &__conte {
            width: 100%;
            height: 70px;
            @include bp-down(md) {
                opacity: 0;
                transition: 0.3s;
                &.swiper-slide-active {
                    opacity: 1;
                    transition: 0.3s;
                }
            }
            a {
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                @include bp-down(md) {
                    flex-wrap: wrap;
                }
                .date {
                    @include robocon;
                    color: $txt-primary-dark;
                    line-height: 1;
                    @include bp-down(md) {
                        width: 100%;
                    }
                }
                .ttl {
                    margin-left: 3rem;
                    line-height: 1;
                    @include bp-down(md) {
                        margin-left: 0;
                    }
                }
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.top-bnr-set {
    @include lay-padding-def();
    background-color: $bg-primary-lighter;
    &__list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @include bp-down(md) {
            flex-wrap: wrap;
        }
        &__item {
            width: 31%;
            @include bp-down(md) {
                width: 48%;
                margin-bottom: 1rem;
            }
            &__link {
                &:hover {
                    @include hover-opacity;
                }
            }
        }
    }
}
.total-support {
    text-align: center;
    padding: 8rem 0 0 0;
    @include bp-down(md) {
        padding-top: 4rem;
    }
    &__txt {
        width: 700px;
        @include zero-auto(4rem, 0);
        line-height: 2;
        text-align: left;
        @include bp-down(md) {
            width: 90%;
            margin: 2rem auto;
            // text-align: left;
        }
    }
    &__btn {
        .mod-btn--base {
            @include zero-auto(4rem, 0);
            @include ie {
                padding: 1.6rem 0 1rem 0;
            }
            @include bp-down(md) {
                margin-top: 3rem;
            }
        }
    }
}
// .consult-set{
//     @include zero-auto(7rem, 0);
//     display: grid;
//     grid-template-rows: 225px;
//     grid-template-columns: 480px;
//     &__thumb {
//         &.pitatto-thumb {
//             grid-column: 1 / 2;
//         }
//         &.smile-thumb {
//             grid-column: 2 / 3;
//         }
//     }
//     &__txt {}
// }
.consult-wrapper {
    position: relative;
    z-index: 2;
    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 262px;
        background-color: $bg-primary-lighter;
        bottom: 0;
        left: 0;
        z-index: 0;
        @include bp-down(md) {
            height: calc(100% - 10rem);
            top: 10rem;
        }
    }
}
.consult-set {
    @include lay-content-inner;
    @include zero-auto(7rem, 0);
    display: flex;
    justify-content: space-between;
    position: relative;
    @include bp-up(md) {
        box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    }
    @include bp-down(md) {
        flex-direction: column;
        margin-top: 5rem;
    }
    &__left {
        width: 50%;
        @include bp-down(md) {
            width: 100%;
            box-shadow: 0 0 10px rgba(0, 0, 0, .05);
        }
    }
    &__right {
        width: 50%;

        @include bp-down(md) {
            width: 100%;
            margin-top: 2rem;
            box-shadow: 0 0 10px rgba(0, 0, 0, .05);
        }
    }
    &__txt {
        padding: 4rem 4.5rem 5rem;
        @include bp-down(md) {
            padding: 3rem 2rem;
        }
        p {
            text-align: left;
            line-height: 2;
        }
        &.pitatto-txt {
            background-color: $bg-muted-light;
        }
        &.smile-txt {
            background-color: #fff;
        }
        .mod-btn--base {
            @include zero-auto(3rem, 0);
            @include ie {
                padding: 1.6rem 0 1rem 0;
            }
        }
    }
}
.net-ins {
    background-color: $bg-primary-lighter;
    padding: 8rem 0;
    @include bp-down(md) {
        padding: 5rem 0;
    }
    &--wrapper {
        @include lay-content-inner;
        @include zero-auto();
    }
    text-align: center;
    &__list {
        margin-top: 4rem;
        display: flex;
        justify-content: space-between;
        @include bp-down(md) {
            flex-wrap: wrap;
            margin-top: 3rem;
        }
        &__item {
            background-color: #ccc;
            width: 24%;
            @include bp-down(md) {
                width: 48%;
                margin-bottom: 2rem;
            }
            a {
                background-color: #ccc;
                img {
                    width: 100%;
                }
                &:hover {
                    img {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
    .bnr-box-area {
        &__inner {
            max-width: 800px;
            margin: 0 auto;
            img {
                width: 100%;
            }
        }
        a {
            &:hover {
                img {
                    opacity: .7;
                    transition: .3s;
                }
            }
        }
    }
}
.ins-link {
    display: block;
    padding: 1.5rem;
    width: 250px;
    text-align: center;
    margin: 4rem auto 0 auto;
    color: #fff;
    font-weight: bold;
    @include ie {
        padding: 1.6rem 0 1.2rem 0;
    }
    @include bp-down(md) {
        margin-top: 1rem;
    }
}

.pitatto-channel {
    background-image: url(../images/common/bg-bk.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4rem 0 8rem 0;
    @include bp-down(md) {
        padding: 3rem 0 5rem 0;
    }
    &__ttl {
        text-align: center;
    }
    &__list {
        width: 720px;
        @include zero-auto(4rem, 4rem);
        display: flex;
        justify-content: space-between;
        @include bp-down(md) {
            @include sp_width_center(2rem, 0);
            flex-direction: column;
        }
        &__item {
            width: calc(100% / 3);
            background-color: #fff;
            position: relative;
            @include bp-down(md) {
                width: 100%;
                & + li {
                    margin-top: 1rem;
                }
            }
            &.new {
                &:before {
                    content: "NEW";
                    position: absolute;
                    @include robocon;
                    color: #fff;
                    width: 50px;
                    height: 25px;
                    text-align: center;
                    top: 0;
                    left: 0;
                    background-color: #e53263;
                    z-index: 2;
                    font-weight: bold;
                    @include bp-down(md) {
                        font-size: 1.1rem;
                        padding-top: 0.5rem;
                    }
                }
            }
            &:nth-child(2) {
                background-color: #f6f6f6;
            }
            &__link {
                display: block;
                position: relative;
                @include bp-down(md) {
                    display: flex;
                }
                &__top {
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        width: 45px;
                        height: 34px;
                        background-image: url(../images/common/icon-movie-play.svg);
                        top: 40%;
                        right: 0;
                        left: 0;
                        margin: auto;
                    }
                    @include bp-down(md) {
                        width: 43%;
                        img {
                            object-fit: cover;
                            object-position: 100% 100%;
                            height: 100px;
                        }
                    }
                }
                &__bottom {
                    padding: 3rem 2.5rem 6rem 2.5rem;
                    height: 155px;
                    @include bp-down(md) {
                        width: 70%;
                        height: 100px;
                        padding: 2rem;
                    }
                    &__txt {
                        font-size: 1.5rem;
                        font-weight: bold;
                    }
                }
                &__date {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    bottom: 1.5rem;
                    left: 2.5rem;
                    @include bp-down(md) {
                        // position: static;
                        right: 11rem;
                        left: auto;
                    }
                    span {
                        display: block;
                        line-height: 1;
                    }
                    .icon-yt {
                        margin-right: 0.5rem;
                    }
                    .date-txt {
                        @include robocon;
                        font-size: 1.3rem;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
.channel-list-link {
    display: block;
    width: 250px;
    @include zero-auto(4rem, 0);
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    padding: 1.5rem 0;
    @include ie {
        padding: 1.6rem 0 1rem 0;
    }
    &:hover {
        opacity: 0.7;
    }
}
.shop-guide {
    background-color: #52c2f0;
    background: linear-gradient(#52c2f0, #a7dbf7);
    background: -webkit-linear-gradient(#52c2f0, #a7dbf7);
    // background-size: cover;
    // background-repeat: repeat-x;
    // background-position: bottom;
    padding: 8rem 0 20rem 0;
    text-align: center;
    position: relative;
    &:after {
        width: 100%;
        height: 170px;
        background-image: url(../images/home/home-shop-bg.png);
        background-repeat: repeat-x;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        @include bp-down(md) {
            height: 60px;
            background-size: cover;
        }
    }
    @include bp-down(md) {
        padding: 5rem 0;
    }
    &__catch {
        color: #00437c;
    }
    &__set {
        @include lay-content-inner;
        @include zero-auto(4rem, 0);
        &__list {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            @include bp-down(md) {
                justify-content: space-around;
                &:after {
                    content: "";
                    width: 46%;
                    visibility: hidden;
                }
            }
            &__item {
                width: 31%;
                margin-bottom: 4rem;
                @include bp-down(md) {
                    width: 46%;
                    margin-bottom: 2rem;
                }
                & + li {
                    margin-left: 30px;
                    @include bp-down(md) {
                        margin-left: 0;
                    }
                }
                &:nth-child(4) {
                    margin-left: 0;
                }
                &__link {
                    position: relative;
                    display: block;
                    &:hover {
                        img {
                            opacity: 0.7;
                        }
                    }
                    @include bp-down(md) {
                        img {
                            object-fit: cover;
                        }
                    }
                    &:before {
                        position: absolute;
                        color: #fff;
                        width: 70px;
                        text-align: center;
                        height: 25px;
                        top: 0;
                        left: 0;
                        z-index: 2;
                        font-size: 13px;
                        font-weight: bold;
                        padding-top: 3px;
                        @include bp-down(md) {
                            font-size: 1.1rem;
                            width: 50px;
                            height: 22px;
                        }
                    }
                    &.nigata {
                        &:before {
                            content: "新潟";
                            background-color: #226bae;
                        }
                    }
                    &.gunma {
                        &:before {
                            content: "群馬";
                            background-color: #381e7f;
                        }
                    }
                    &--txt {
                        font-weight: bold;
                        margin-top: 2rem;
                        font-size: 1.7rem;
                        @include bp-down(md) {
                            margin-top: 1.5rem;
                            font-size: 1.5rem;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }
}
.home-comp-info {
    background-color: $bg-primary-lighter;
    padding: 8rem 0 13rem 0;
    @include bp-down(md) {
        padding: 5rem 0 8rem 0;
    }
    &--bnr-list {
        max-width: 960px;
        @include zero-auto(0, 4rem);
        display: flex;
        justify-content: space-between;
        gap:24px;
       
        &:first-child{
            @include zero-auto(0, 2rem);
        }
        @include bp-down(md) {
            // @include sp_width_center();
          display: block;
            width: 90%;
            margin: 0 auto;
            flex-wrap: wrap;
          
        }
        &__item {

            &__link {
                &:hover {
                    opacity: 0.7;
                }
            }
            @include bp-down(md) {
                margin-bottom: 1.5rem;
            }
        }
    }
    &--rec {
        margin-top: 4rem;
        @include bp-down(md) {
            margin-top: 2rem;
            text-align: center;
        }
        a {
            display: block;
            max-width: 960px;
            margin: 0 auto;
            &:hover {
                opacity: 0.7;
                transition: 0.2s;
            }
        }
        &__center {
            @include bp-down(md) {
                width: 100%;
                padding: 2rem 0;
            }
            &__ttl {
                font-size: 2.5rem;
                line-height: 1;
                @include bp-down(md) {
                    font-size: 2rem;
                }
            }
            &__btn {
                display: block;
                color: #fff;
                font-weight: bold;
                text-align: center;
                width: 260px;
                @include zero-auto(2rem, 0);
                border-radius: 5px;
                padding: 1.5rem 0;
                @include ie {
                    padding: 1.6rem 0 1rem 0;
                }
                @include bp-down(md) {
                    margin-top: 1.5rem;
                    width: 240px;
                }
            }
        }
    }
}
.numb-list-box,
.dots-list-box,
.cap-list-box,
.tab-menu-box,
.card-box,
.scroll-content-box {
    padding: $padding-default 0;
}
.dots-list-box,
.tab-menu-box,
.scroll-content-box {
    background-color: $bg-muted-light;
}

.company-mv{
    h2{

    }
}
