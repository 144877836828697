@use "../mixins" as *;
@use "../variables" as *;

.insurance {
    &--main {
        padding: 10rem 0 13rem 0;
        position: relative;
        background-color: $bg-primary-lighter;
        .page-ttl--ttl--child {
            // margin-top: 5rem;
        }
        @include bp-down(md) {
            padding: 3rem 0 4rem 0;
        }
    }
    // p {
    //     line-height: 2;
    // }
    .page-head {
        background-color: $bg-primary-lighter;
        padding-top: 0;
        @include bp-down(md) {
            padding-bottom: 0;
        }
    }
}
.pitatto-logo {
    // position: absolute;
    position: relative;
    z-index: 2;
    // margin: auto;
    // width: 350px;
    // max-width: 960px;
    // height: 250px;
    img {
        width: 359px;
        height: 250px;
        position: absolute;
        top: -20rem;
        left: 0;
        right: 0;
        margin: auto;
    }
    @include bp-down(md) {
        img {
            width: 170px;
            height: auto;
            top: -10rem;
        }
    }
}
.insurance-thumbs-set-wrap {
    @include lay-content-inner;
    @include zero-auto(0, -16rem);
}
.insurance-thumbs-set {
    // @include lay-content-inner;
    // @include zero-auto(4rem, -16rem);
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    &__item {
        width: 50%;
    }
}
.ins-prod {
    background-color: #fff;
    padding: 23rem 0 8rem 0;
    @include bp-down(md) {
        padding-bottom: 2rem;
    }
    .page-ttl-catch--insurance {
        text-align: center;
    }
    .page-ttl--ttl {
        text-align: center;
    }
}
.page-ttl-catch--insurance {
    // text-align: center;
}
.tab-catch {
    text-align: center;
    margin-top: 4rem;
}

.ins-prod-set {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &__item {
        width: 30%;
        margin-bottom: 3rem;
        @include bp-down(md) {
            width: 48%;
        }
        h3 {
            text-align: center;
            font-size: 1.7rem;
            margin-top: 2rem;
            @include bp-down(md) {
                font-size: 1.3rem;
                margin-top: 1rem;
            }
        }
    }
}
.ins-prod-graph {
    display: block;
    width: 700px;
    @include zero-auto();
}
.consult-flow {
    padding: 8rem 0;
    @include bp-down(md) {
        padding: 4rem 0 0 0;
    }
    .page-ttl--ttl {
        text-align: center;
    }
}
.consult-flow-set {
    &__list {
        @include lay-content-inner();
        @include zero-auto(4rem, 0);
        display: flex;
        justify-content: space-between;
        @include bp-down(md) {
            flex-wrap: wrap;
        }
        &__item {
            width: 22%;
            position: relative;
            @include bp-down(md) {
                width: 100%;
                margin-bottom: 4rem;
                img {
                    width: 100%;
                }
            }
            & + li {
                &:before {
                    position: absolute;
                    content: "";
                    width: 40px;
                    height: 4px;
                    background-color: $bg-primary;
                    top: 18%;
                    left: -40px;
                    @include bp-down(md) {
                        content: none;
                    }
                }
            }
            .step-numb {
                position: absolute;
                background-color: $bg-primary;
                color: #fff;
                padding: 0.5rem 0;
                width: 100px;
                border-radius: 20px;
                font-weight: bold;
                text-align: center;
                left: 0;
                right: 0;
                margin: auto;
                top: -15px;
            }
            &__ttl {
                font-size: 1.7rem;
                text-align: center;
                margin-top: 2rem;
            }
            &__txt {
                margin-top: 2rem;
                line-height: 1.8;
                @include bp-down(md) {
                    margin-top: 1rem;
                }
            }
        }
    }
}
.ins-voice {
    padding: 8rem 0 0 0;
    @include bp-down(md) {
        padding: 3rem 0 0 0;
    }
    .align-pc-center {
        text-align: center;
    }
    .page-ttl-catch {
        text-align: center;
        @include bp-down(md) {
            text-align: left;
        }
    }
}
.voice__contents--insurance {
    @include lay-content-inner();
    @include zero-auto(4rem, 0);
}
.ins-back-btn {
    width: 250px;
    @include zero-auto(7rem, 0);
    display: block;
    font-weight: bold;
    @include bp-down(md) {
        margin-top: 3rem;
    }
}
.ins-row-lay {
    display: flex;
    justify-content: space-between;
    @include lay-content-inner;
    @include zero-auto(5rem, 0);
    border: 2px solid $border-primary;
    background-color: #f6f6f6;
    @include bp-down(md) {
        margin-top: 3rem;
        flex-direction: column;
    }
    &.aic {
        align-items: center;
    }
    &:first-child {
        margin-top: 7rem;
    }
    &__item {
        padding: 5rem;
        width: 50%;
        // height: 100%;
        @include bp-down(md) {
            width: 100%;
            padding: 2rem;
        }
        &--txt {
            background-color: #fff;
            &__ttl {
                font-size: 3rem;
                color: $txt-primary-dark;
                @include bp-down(md) {
                    font-size: 2rem;
                }
            }
            &__ttl-sub {
                font-size: 2rem;
                margin-top: 3.5rem;
                @include bp-down(md) {
                    font-size: 1.5rem;
                }
            }
            &__txt {
                margin-top: 2rem;
                line-height: 2;
                &--cap {
                    font-size: 1.2rem;
                }
                @include bp-down(md) {
                    margin-top: 1rem;
                }
            }
        }
        &--graph {
            background-color: $bg-muted-light;
            &__item {
                padding-bottom: 2rem;
                & + div {
                    border-top: 2px dotted #ccc;
                    padding-top: 2rem;
                }
            }
        }
        &--thumbs {
            padding: 0;
            &__item {
                height: 100%;
            }
            &.ins-point-img {
                padding: 0;
                overflow: hidden;
                width: 100%;
                height: 25rem;
                @include bp-up(md) {
                    width: 50%;
                    height: auto;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        &--rec {
            border-top: 2px dotted #ccc;
            margin-top: 2.5rem;
            &__ttl {
                color: $txt-primary-dark;
                font-size: 1.5rem;
                margin-top: 2.5rem;
            }
            .lay-list-desc-set--green {
                margin-top: 1.5rem;
            }
        }
    }
}
.insurance-links {
    @include lay-content-inner;
    @include zero-auto(7rem, 4rem);
    width: 100%;
    padding: 3rem;
    background-color: #f0f2d5;
    box-shadow: none;
    @include bp-down(md) {
        padding: 2rem;
        margin: 2rem auto;
    }
    &__ttl {
        font-size: 2rem;
        color: $txt-primary-dark;
        text-align: center;
    }
    &__list {
        margin-top: 2rem;
        &__item {
            &__link {
                font-size: 1.5rem;
                padding: 1.5rem 3rem;
                &.current {
                    background-color: $bg-primary !important;
                    color: #fff;
                    border-left: 3px solid $border-primary-dark;
                    pointer-events: none;
                    .mod-list__item__numb {
                        color: #fff;
                    }
                    &:after {
                        background-image: url(../images/common/arrow-wh.svg);
                    }
                }
                .mod-list__item__numb {
                    font-size: 2rem;
                }
            }
        }
    }
}
.risk-hedge--insurance {
    .risk-hedge__ttl {
        @include bp-down(md) {
            text-align: center;
        }
    }
}
.point01-main-catch {
    max-width: 800px;
    @include zero-auto();
    @include bp-down(md) {
        width: 90%;
    }
}
.point-main-catch {
    max-width: 800px;
    @include zero-auto(4rem, 0);
    @include bp-down(md) {
        width: 90%;
        margin-top: 2rem;
    }
}
.inner-page-link-dot {
    max-width: 630px;
    @include zero-auto(4rem, 7rem);
    @include bp-down(md) {
        margin: 2rem auto 3rem auto;
    }
    &__set {
        display: flex;
        justify-content: space-between;
        @include bp-down(md) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        &__item {
            width: 300px;
            @include bp-down(md) {
                & + li {
                    margin-top: 2rem;
                }
            }
            &__link {
                background-color: #fff;
                border: 3px dotted $border-primary;
                border-radius: 10px;
                display: block;
                color: $txt-primary-dark;
                text-align: center;
                padding: 2.5rem 0 2rem 0;
                font-weight: bold;
                &:hover {
                    color: $txt-primary-dark;
                    opacity: 0.8;
                }
                &__catch {
                    font-size: 1.7rem;
                    @include bp-down(md) {
                        font-size: 1.4rem;
                    }
                }
                &__ttl {
                    font-size: 2.5rem;
                    @include bp-down(md) {
                        font-size: 2rem
                    }
                }
            }
        }
    }
}
.inner-page-link-dot02 {
    @include lay-content-inner;
    @include zero-auto(3rem, 7rem);
    &__set {
        display: flex;
        justify-content: space-between;
        @include bp-down(md) {
            flex-direction: column;
        }
        &__item {
            width: 30%;
            position: relative;
            @include bp-down(md) {
                width: 100%;
                & + li {
                    margin-top: 2rem;
                }
            }
            &__point {
                color: #fff;
                background-color: $bg-primary;
                text-align: center;
                font-size: 1.5rem;
                width: 100px;
                position: absolute;
                top: -1.2rem;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 2;
                padding: 0.2rem 0;
                border-radius: 16px;
            }
            &__link {
                background-color: #fff;
                border: 3px dotted $border-primary;
                border-radius: 10px;
                display: block;
                color: $txt-primary-dark;
                text-align: center;
                padding: 2.5rem 0 2rem 0;
                font-weight: bold;
                &:hover {
                    color: $txt-primary-dark;
                    opacity: 0.8;
                }
                &__catch {
                    font-size: 1.7rem;
                }
            }
        }
    }
}
.ins-one-row-lay {
    padding: 7rem 0;
    // margin-top: 7rem;
    @include bp-down(md) {
        padding: 3rem 0;
    }
    &:first-child {
        margin-top: 0;
    }
    &--bg-white {
        background-color: #fff;
    }
    &--border-green {
        border-bottom: 2px solid $bg-muted-light;
    }
    &__inner {
        max-width: 800px;
        @include zero-auto();
    }
    &__ttl {
        text-align: center;
        font-size: 3rem;
        @include bp-down(md) {
            font-size: 2rem;
            width: 90%;
            margin: 0 auto;
        }
    }
    &__catch {
        margin-top: 3.5rem;
        @include bp-down(md) {
            width: 90%;
            margin: 2rem auto;
            line-height: 1.8;
        }
        &--margin-none {
            margin-top: 0;
        }
    }
}
.section-belt {
    img {
        width: 100%;
    }
}
.compensation {
    background-color: $bg-accent1-lightest;
    padding: 6rem 0 7rem 0;
    position: relative;
    &__set {
        @include lay-content-inner;
        @include zero-auto();
        &__list {
            display: flex;
            justify-content: space-between;
            @include bp-down(md) {
                flex-wrap: wrap;
            }
            &__item {
                width: 25%;
                padding: 18rem 4rem 4rem 4rem;
                position: relative;
                @include bp-down(md) {
                    width: 100%;
                    padding: 2rem;
                    display: flex;
                    .sp-lay {
                        width: 60%;
                    }
                }
                &:nth-child(odd) {
                    background-color: #fff;
                }
                &:nth-child(even) {
                    background-color: #f6f6f6;
                }
                &__ttl {
                    color: $txt-primary-dark;
                    text-align: center;
                    font-size: 1.6rem;
                    @include bp-down(md) {
                        font-size: 1.7rem;
                    }
                }
                &__txt {
                    font-size: 1.3rem;
                    margin-top: 1.7rem;
                }
                &__thumb {
                    position: absolute;
                    top: 4rem;
                    left: 0;
                    right: 0;
                    margin: auto;
                    @include bp-down(md) {
                        position: unset;
                        width: 80px;
                    }
                    &--box03 {
                        top: 3rem;
                        @include bp-down(md) {
                            width: auto;
                            height: 80px;
                        }
                    }
                    &--box04 {
                        top: 3.5rem;
                    }
                }
            }
        }
    }
}
.compensation02 {
    background-color: $bg-accent1-lightest;
    padding: 6rem 0 7rem 0;
    position: relative;
    &__set {
        @include lay-content-inner;
        @include zero-auto();
        &__list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @include bp-down(md) {
                flex-wrap: wrap;
            }
            &__item {
                width: 25%;
                padding: 18rem 4rem 4rem 4rem;
                position: relative;
                // &.sp-gray {
                //     background-color: #f6f6f6;
                // }
                // &.sp-white {
                //     background-color: #fff;
                // }
                @include bp-down(md) {
                    width: 50%;
                    padding: 10rem 3rem 3rem 3rem;
                    &:nth-child(3) {
                        background-color: #f6f6f6;
                    }
                    &:nth-child(4) {
                        background-color: #fff;
                    }
                    &:nth-child(5) {
                        background-color: #fff;
                    }
                    &:nth-child(6) {
                        background-color: #f6f6f6;
                    }
                }
                &--bg-wh {
                    background-color: #fff;
                }
                &--bg-gray {
                    background-color: #f6f6f6;
                }
                &__ttl {
                    // color: $txt-primary-dark;
                    text-align: center;
                    font-size: 2rem;
                }
                &__txt {
                    font-size: 1.3rem;
                    margin-top: 1.7rem;
                }
                &__thumb {
                    position: absolute;
                    top: 4rem;
                    left: 0;
                    right: 0;
                    margin: auto;
                    @include bp-down(md) {
                        top: 2rem;
                        width: 60px;
                    }
                    &--box03 {
                        top: 3rem;
                    }
                    &--box04 {
                        top: 3.5rem;
                    }
                }
            }
        }
    }
}

.fire-ins-point {
    margin-top: 4rem;
}
.earthquake-map {
    background-color: #fff;
    padding-bottom: 7rem;
    &__inner {
        background-color: #ecf5f8;
        @include lay-content-inner;
        @include zero-auto();
        position: relative;
        padding: 5.5rem 0 4rem 0;
    }
    &__ttl {
        color: $txt-primary-dark;
        text-align: center;
        position: absolute;
        z-index: 2;
        padding: 0.5rem;
        border: 2px solid #ccc;
        border-radius: 20px;
        top: -2rem;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #fff;
        max-width: 600px;
        font-size: 1.7rem;
    }
    &__map {
        max-width: 680px;
        @include zero-auto();
    }
}
.direct-child-inner-link {
    max-width: 720px;
    @include zero-auto(4rem, 7rem);
    &__list {
        display: flex;
        flex-wrap: wrap;
        @include bp-down(md) {
            padding: 0 15px;
        }
        &__item {
            display: flex;
            width: calc(100% / 3);
            text-align: center;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            &:first-child {
                border-left: none;
            }
            &:nth-child(4) {
                border-left: none;
            }
            &:nth-child(7) {
                border-left: none;
            }
            @include bp-down(md) {
                width: calc(100% / 2);
                &:nth-child(3) {
                    border-left: none;
                }
                &:nth-child(4) {
                    border-left: 2px solid #fff;
                }
                &:nth-child(5) {
                    border-left: none;
                }
            }
            &__link {
                text-align: center;
                display: block;
                width: 100%;
                background-color: $bg-primary;
                color: #fff;
                padding: 1.5rem 0;
                position: relative;
                &:hover {
                    opacity: 0.8;
                    color: #fff;
                }
                &:before {
                    display: inline-block;
                    margin-right: 1rem;
                    content: "";
                    background-image: url(../images/common/arrow-wh-under.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 12px;
                    height: 8px;
                    z-index: 2;
                }
            }
        }
    }
}
.direct-link-bnr {
    background-color: #F0F2D5;
    padding: 5rem 0;
    a {
        display: block;
        max-width: 800px;
        margin: 0 auto;
        @include bp-down(md) {
            max-width: 90%;
        }
        &:hover {
            opacity: .7;
        }
        img {
            width: 100%;
        }
    }
}
.ins-direct-lead {
    text-align: center;
    &--sub {
        font-size: 1.2rem;
    }
}
