@use "../mixins" as *;
@use "../variables" as *;

/* ---------------------------------------------
*   contact
--------------------------------------------- */
.contents--main--contact {
    background-color: $bg-primary-lighter;
    padding: 7rem 0 13rem 0;
    @include bp-down(md) {
        padding: 3rem 0 4rem 0;
    }
    &.contact-confirm {
        .contact-form__desc {
            padding: 3rem;
            @include bp-down(md) {
                padding: 1.5rem;
            }
        }
    }
}
.contact-catch {
    text-align: center;
    &:first-child {
        margin-bottom: 20px;
    }
}
// .contact {
//     &__container {
//         max-width: 960px;
//         margin: 0 auto;
//         padding: 90px 0 70px;
//         box-sizing: border-box;

//         @include bp-down(md) {
//             padding: pxtovw(50, "sp") pxtovw(15, "sp");
//         }
//         .conf-conte {
//             border-top: 1px solid #ccc;
//             margin-top: 3rem;
//             .contact-form__list {
//                 .contact-form__term {
//                     width: 30%;
//                     margin: 0;
//                     background-color: #f6f6f6;
//                     .contact-form__txt {
//                         margin: 0;
//                     }
//                 }
//                 .contact-form__desc {
//                     margin: 0;
//                 }
//             }
//         }
//         .comp-conte {
//             p {
//                 margin-bottom: 1rem;
//                 &.thanks-txt {
//                     font-size: 1.4rem;
//                     font-weight: 600;
//                 }
//             }
//         }
//     }
//     .contact-form {
//         &__list {
//             display: flex;
//             align-items: center;
//         }
//         &__term {
//             background-color: #f6f6f6;
//         }
//         &__desc {
//             background-color: #fff;
//             padding: 2rem 3rem;
//         }
//     }

//     &__lead {
//         font-size: 1.6rem;
//         font-weight: bold;
//         text-align: center;

//         @include bp-down(md) {
//             font-size: pxtorem(16, "sp");
//             text-align: left;
//         }
//     }
// }

/*  contact-policy
--------------------------------------------- */
.contact-policy {
    margin-top: 35px;
    overflow: hidden;
    background-color: #F0F2D5;
    padding: 3rem;

    @include bp-down(md) {
        padding: 2rem;
    }
    p {
        font-size: 1.3rem;
    }
    .privacy-sec {
        font-size: 2rem;
        margin-top: 2rem;
    }
    .privacy-sec__txt {
        margin-top: 1rem;
    }
    .lay-list-desc-set-green__item {
        font-size: 1.3rem;
        margin-top: .5rem;
    }
    .transaction-sec {
        font-size: 2rem;
        margin-top: 1.5rem;
    }

    &__container {
        height: 250px;
        padding: 20px 30px;
        overflow-y: auto;
        box-sizing: border-box;
        background-color: #fff;

        @include bp-down(md) {
            padding: 1.5rem;
        }
    }

    &__head {
        font-size: 2rem!important;
        font-weight: bold;

        @include bp-down(md) {
            font-size: 2rem;
        }
    }

    &__txt {
        margin-top: $margin-default;
        font-size: $font-size-default;
        font-weight: 500;

        @include bp-down(md) {
            font-size: 1.2rem;
            margin-top: 1rem;
        }

        &:last-of-type {
            @at-root .ie &,
                .windows.firefox & {
                margin-bottom: 20px;
            }
        }
    }
}

/*  contact-form
--------------------------------------------- */
.contact__contents {
    @include lay-content-inner;
    @include zero-auto();
}
.contact-form {
    margin-top: 40px;
    p.error {
        color: #e6243f;
        margin-top: 0.5rem;
        &.agree-error {
            margin-top: 0;
            margin-left: 1rem;
            @include bp-down(md) {
                margin-top: 0.5rem;
                margin-left: 0;
            }
        }
    }
    @include bp-down(md) {
        margin-top: 2rem;
    }

    &__label {
        display: inline-block;
        padding: 5px 12px;
        font-size: $font-size-small;
        font-weight: bold;
        color: $txt-light;
        border-radius: 3px;

        @include bp-down(md) {
            padding: pxtovw(1, "sp") pxtovw(12, "sp");
            font-size: pxtorem(12, "sp");
            border-radius: pxtovw(10, "sp");
        }

        &--require {
            background-color: $bg-primary;
        }

        &--optional {
            background-color: $border-default;
        }
    }
    .agree-check-box {
        position: relative;
        text-align: center;
    }

    &__agree {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 5px;
        margin-top: 3rem;
        &__txt {
            font-weight: bold;
            margin-right: 2rem;
        }
        @include bp-down(md) {
            display: block;
            padding-bottom: pxtovw(15, "sp");
        }

        input {
            display: none;

            &:checked {
                & + label {
                    &:after {
                        content: "";
                        position: absolute;
                        top: -3px;
                        left: 5px;
                        height: 2px;
                        width: 7px;
                        height: 14px;
                        transform: rotate(40deg);
                        border-bottom: 3px solid $border-default;
                        border-right: 3px solid $border-default;

                        @include bp-down(md) {
                            top: pxtovw(-3, "sp");
                            left: pxtovw(5, "sp");
                            height: pxtovw(2, "sp");
                            width: pxtovw(7, "sp");
                            height: pxtovw(14, "sp");
                            border-width: pxtovw(3, "sp");
                        }
                    }
                }
            }
        }

        label {
            position: relative;
            // margin-left: 14px;
            // padding-left: 23px;
            vertical-align: middle;
            font-size: $font-size-middle;
            font-weight: bold;
            box-sizing: border-box;

            @include bp-down(md) {
                display: block;
                width: 100%;
                margin-top: pxtovw(10, "sp");
                margin-left: 0;
                padding-left: pxtovw(23, "sp");
                font-size: pxtorem(14, "sp");
            }
        }
    }

    &__list {
        display: flex;
        width: 100%;
        @include bp-down(md) {
            display: block;
        }
        &.bb {
            dt {
                border-bottom: 1px solid #ccc;
            }
            dd {
                border-bottom: 1px solid #ccc;
            }
        }
    }
    dl {
        &:last-child {
            dt {
                border-bottom: 1px solid #ccc;
            }
        }
    }

    &__term {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 300px;
        background-color: #f6f6f6;
        padding: 3rem;
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        &.last {
            border-bottom: 1px solid #ccc;
            @include bp-down(md) {
                border-bottom: none;
            }
        }
        &.vt {
            align-items: flex-start;
        }
        @include bp-down(md) {
            width: 100%;
            padding: 1.5rem;
            border-right: 1px solid #ccc;
            align-items: center;
        }
    }

    &__txt {
        margin-left: 15px;
        font-size: $font-size-default;
        font-weight: bold;

        @include bp-down(md) {
            margin: 0;
            font-size: 1.5rem;
        }
    }

    &__desc {
        width: 660px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: #fff;
        padding: 2rem 3rem;
        border-top: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
        &.last {
            border-bottom: 1px solid #ccc;
        }
        &--radio {
            justify-content: flex-start;
            align-items: center;
            label {
                cursor: pointer;
                margin-left: 2rem;
                @include bp-down(md) {
                    margin-left: 0;
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .mail_confirm {
            margin: 0.5rem 0;
        }
        @include bp-down(md) {
            display: block;
            width: 100%;
            padding: 1.5rem;
        }
        .postal-code-wrapper {
            position: relative;
            &:before {
                display: flex;
                align-items: center;
                justify-content: center;
                content: "〒";
                position: absolute;
                top: 1px;
                left: 1px;
                text-align: center;
                width: 50px;
                height: 51px;
                border-right: 1px solid #ccc;
                background-color: #f6f6f6;
                color: #212a37;
                border-radius: 4px 0 0 4px;
                @include bp-down(md) {
                    bottom: 0;
                    height: calc(100% - 1px);
                }
            }
            input {
                padding-left: 6.7rem !important;
                position: relative;
            }
        }

        input[type="text"],
        input[type="tel"],
        input[type="email"] {
            appearance: none;
            width: 100%;
            // height: 30px;
            padding: 15px;
            border: 1px solid $border-default;
            outline: none;
            background: none;
            border-radius: 4px;
            font-size: $font-size-middle;
            font-weight: 500;
            box-sizing: border-box;
            border: 1px solid #ccc;

            @include bp-down(md) {
                padding: 1.5rem;
                font-size: 1.3rem;
            }
        }

        textarea {
            width: 100%;
            height: 300px;
            appearance: none;
            resize: none;
            padding: 6px 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            outline: none;
            background-color: $bg-light;
            box-sizing: border-box;

            @include bp-down(md) {
                height: pxtovw(200, "sp");
                padding: pxtovw(10, "sp");
                border-radius: pxtovw(4, "sp");
            }
        }
        .shop-select-wrapper {
            position: relative;
            width: 100%;
            select {
                width: 100%;
                border: 1px solid #ccc;
                padding: 1.5rem;
                border-radius: 5px;
                appearance: none;
            }
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 8px;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 4px 0 4px;
                border-color: #333 transparent transparent transparent;
            }
        }
    }

    &__address {
        width: 100%;
        margin-top: 15px;

        @include bp-down(md) {
            margin-top: 1rem;
        }

        &--half {
            width: 290px;
            margin-top: 5px;

            @include bp-down(md) {
                width: 100%;
            }
        }

        input {
            padding: 0;
            border: none;
            border-radius: 0;
            outline: none;
            background: none;
            appearance: none;
            // margin-top: 4px;

            @include bp-down(md) {
                margin-top: pxtovw(5, "sp");
            }
        }

        select {
            appearance: none;
            border: 1px solid #ccc;
            border-radius: 4px;
            outline: none;
            width: 100%;
            // height: 30px;
            padding: 0 10px;
            background-color: $bg-light;
            box-sizing: border-box;
            cursor: pointer;
            font-size: $font-size-middle;
            vertical-align: middle !important;
            padding: 15px;

            @include bp-down(md) {
                height: pxtovw(30, "sp");
                border-radius: pxtovw(4, "sp");
                padding: 0 pxtovw(10, "sp");
                font-size: pxtorem(16, "sp");
            }

            &::-ms-expand {
                display: none;
            }

            &::-ms-value {
                background: none;
                color: $txt-default;
            }
        }
    }

    &__address-head {
        font-size: $font-size-middle;
        font-weight: bold;

        @include bp-down(md) {
            font-size: pxtorem(14, "sp");
        }
    }

    &__prefectures {
        position: relative;

        @include bp-down(md) {
            margin-top: pxtovw(4, "sp");
        }

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-color: #333 transparent transparent transparent;
        }
    }

    &__submit {
        text-align: center;
        width: 250px;
        // height: 55px;
        border-radius: 3px;
        @include zero-auto(5rem,0);
        .mod-btn {
            width: 250px;
            border: none;
            font-weight: bold;
            &:hover {
                opacity: .7;
            }
            &.send {
                color: #fff;
                margin-left: 2rem;
                @include bp-down(md) {
                    margin-left: 0;
                    margin-bottom: 1rem;
                }
            }
            &.back {
                &:hover {
                    color: #333;
                }
            }
        }
        &.conf {
            display: flex;
            justify-content: center;
            @include bp-down(md) {
                flex-direction: column-reverse;
                form {
                    margin-bottom: 1rem;
                }
            }
        }
        @include bp-down(md) {
            margin-top: 4rem;
        }

        input {
            appearance: none;
            padding: 0;
            border: none;
            outline: none;
            background: transparent;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            width: 250px;
            height: 55px;
            font-size: $font-size-default;
            font-weight: bold;
            color: $txt-light;
            border-radius: 4px;
            cursor: pointer;

            @include bp-down(md) {
                width: 100%;
                height: pxtovw(44, "sp");
                font-size: pxtorem(16, "sp");
                border-radius: pxtovw(4, "sp");
            }
        }
        &__btn {
            appearance: none;
            padding: 0;
            border: none;
            outline: none;
            background: transparent;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            width: 312px;
            height: 44px;
            font-weight: bold;
            color: $txt-light;
            background-color: $bg-primary;
            box-shadow: 0.5px 0.866px 3px 0px rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            cursor: pointer;

            @include bp-down(md) {
                width: 100%;
                height: pxtovw(44, "sp");
                font-size: pxtorem(16, "sp");
                border-radius: pxtovw(4, "sp");
            }
        }
    }
}
.confirm-ttl {
    text-align: center;
    margin-bottom: 2rem;
}
.contact__lead {
    text-align: center;
    margin-bottom: 4rem;
}
.complete-box {
    text-align: center;
    .complete-catch {
        margin-top: 4rem;
    }
}
.contact {
    @include bp-down {
        .grecaptcha-badge {
            transform: translateY(-70px);
        }
    }
}
.form__submit__btn {
    text-align: center;
    margin: 4rem auto 0 auto;
}
