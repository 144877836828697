//fontsize
$font-size-default: 1.5rem;
$font-size-large: 2.4rem;
$font-size-middle: 1.4rem;
$font-size-small: 1.1rem;
$font-size-default-sp: 1.4rem;
$root-fz-pc: 1.6;
$root-fz-tb: 14;
$root-fz-sp: 14;

//margin
$margin-default: 2rem;

//padding
$padding-default: 4rem;

//radius
$radius-default: .5rem;

//shadow
$shadow-default: 5px 5px 5px rgba(0,0,0,.2);

//line height
$line-height-pc: 1.5;
//$line-height-tb: 1.5;
$line-height-sp: 1.5;