@use "../mixins" as *;
@use "../variables" as *;

.contents--main--invitation {
	background-color: $bg-primary-lighter;
	padding: 7rem 0 13rem 0
}
.basic-policy {
	@include lay-content-inner;
	@include zero-auto();
	padding: 5rem;;
	background-color: #fff;
	border: 2px solid $border-primary;
	.lay-list-desc-set-green {
		margin-top: 3rem;
		.lay-list-desc-set-green__item {
			font-weight: bold;
			font-size: 1.7rem;
			& + li {
				margin-top: 1rem;
			}
		}
	}
}
.appropriate {
	&__inner {
		@include lay-content-inner;
		@include zero-auto(5rem,0);
	}
	&__ttl {
		font-size: 2rem;
		margin-top: 5rem;
	}
	.lay-list-desc-set-green {
		margin-top: 2.5rem;
		.lay-list-desc-set-green__item {
			font-size: 1.5rem;
			& + li {
				margin-top: .5rem;
			}
		}
	}
	.date {
		text-align: right;
		@include lay-content-inner;
		@include zero-auto(5rem, 0);
	}
}
