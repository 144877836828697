@use "../mixins" as *;
@use "../variables" as *;

/* ---------------------------------------------
*   title
--------------------------------------------- */

/*  mod-ttl
--------------------------------------------- */
.mod-ttl {
	$host: &;
	position: relative;
	margin-bottom: 60px;
	font-size: $font-size-large;
	font-weight: bold;
	text-align: center;

	@include bp-down(md) {
		margin-bottom: pxtovw(40, 'sp');
		font-size: pxtorem(28, 'sp');
	}

	&:before {
		content: "";
		position: absolute;
		bottom: -18px;
		left: 50%;
		transform: translateX(-50%);
		width: 25px;
		height: 5px;
		background: url(/assets/image/common/icon_dots_green.png) 0 0 no-repeat;
		background-size: 100% 100%;

		@include bp-down(md) {
			bottom: pxtovw(-18, 'sp');
			width: pxtovw(25, 'sp');
			height: pxtovw(5, 'sp');
		}
	}

	.green {
		color: $txt-primary;
	}

	&--white {
		color: $txt-light;

		&:before {
			background-image: url(/assets/image/common/icon_dots_white.png);
		}
	}
}
