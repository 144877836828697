@use "../mixins" as *;
@use "../variables" as *;

.contents--main--accident {
    padding: 7rem 0 13rem 0;
    background-color: $bg-primary-lighter;
    @include bp-down {
        padding-top: 3rem;
    }
}
.accident-catch {
    text-align: center;
}
.accident-table-wrap {
    @include lay-content-inner;
    @include zero-auto(2rem ,0);
    .accident-table {
        width: 100%;
        background-color: #fff;
        .bg-gray {
            background-color: #f6f6f6;
            @include bp-down(md) {
                background-color: #fff;
            }
        }
        tr {
            @include bp-down(md) {
                &:first-child {
                    th {
                        border-top: 1px solid #ccc;
                    }
                }
            }
        }
        th {
            width: 31%;
            padding: 0 3rem;
            vertical-align: middle;
            border: 1px solid #ccc;
            @include bp-down(md) {
                display: block;
                width: 100%;
                padding: 1.5rem;
                background-color: #f6f6f6!important;
                border-top: none;
            }
        }
        td {
            border: 1px solid #ccc;
            padding: 2.5rem 3rem;
            @include bp-down(md) {
                display: block;
                width: 100%;
                padding: 1.5rem;
                border-top: none;
                // border-bottom: none;
            }
            .tel {
                a {
                    @include robocon;
                    font-size: 2.5rem;
                    span {
                        font-size: 1/3rem;
                    }
                }
            }
            .ttl {
                font-weight: bold;
                color: $txt-primary-dark;
            }
        }
        .upper {
            padding-bottom: 2rem;
            border-bottom: 1px dashed #ccc;
        }
        .downer {
            padding-top: 2rem;
        }
    }
}
